<template>
  <div class="row">
    <div class="col l7 s7">
      <gmap-map
        :center="center"
        :zoom="5"
        map-type-id="terrain"
        style="width: 100%; height: 600px"
        ref="jobMap">
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          @click="showDetails(m)"/>
      </gmap-map>
    </div>
    <div class="col l5 s5 offer-descriptions">
      <div v-for="offer in selectedOffers" class="offer-item">
        <div class="offer-item__name">{{offer.name}}</div>
        <div class="offer-item__detail">
          <ul>
            <li><span class="icon-ico-40-date"></span>{{formatDate(offer.date)}}</li>
            <li><span class="icon-ico-42-money"></span>$ {{offer.price}}</li>
            <li><span class="icon-company"><img src="../../assets/images/company1.png" alt=""></span>{{offer.company.name}}</li>
            <li><span class="icon-ico-53-rate"></span>{{offer.company.rating || 'N/A' }}</li>
          </ul>
        </div>
        <div class="offer-item__info">
          <p>{{offer.description}}</p>
          <ul>
            <li><span>Type:</span>{{offer.offerType.name}}</li>
            <li><span class="icon-ico-43-category"></span>{{offer.category.name}}</li>
            <li><span class="icon-ico-44-location"></span>{{offer.location.formatted_address}}</li>
            <li v-if="offer.conference_link">
              <span>Conference link</span>
              <template v-for="(part, index) in parseConferenceLink(offer.conference_link)">
                <a v-if="part.isUrl" :href="part.text" target="_blank" style="margin-right: 10px; color: #575757">{{ part.text }}</a>
                <span v-else style="color: #575757">{{ part.text }}</span>
                <span v-if="index < parseConferenceLink(offer.conference_link).length - 1" style="color: #9d9d9d">|</span>
              </template>
            </li>
          </ul>
          <router-link tag="div" :to="'/jobBoard/' + offer.id" class="offer-item__button">
            <app-button cssClass="btn--default" title="View" :slideOnHover="false"/>
          </router-link>
        </div>
      </div>
      <div v-if="!selectedOffers">
        Click on a marker to see job offer details
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import Button from '../../components/Button.vue'
  import LinkHelper from '@/helpers/LinkHelper'
  import DateHelper from '@/helpers/DateHelper'

  export default {
    mixins: [LinkHelper, DateHelper],
    data () {
      return {
        center: {lat: 38.422114, lng: -98.717848},
        markers: [],
        selectedOffers: null
      }
    },
    watch: {
      allOpenOffers (offers) {
        this.markers = offers.map(offer => {
          offer.position = offer.location
          return offer
        })
      },
      profileInfo (profile) {
        this.setStartingLocation(profile)
      }
    },
    computed: {
      ...mapGetters(['allOpenOffers', 'profileInfo'])
    },
    methods: {
      ...mapActions(['fetchAllOpenOffers']),
      showDetails (marker) {
        this.selectedOffers = this.allOpenOffers.filter(offer => {
          return offer.location.lat === marker.location.lat && offer.location.lng === marker.location.lng
        })
      },
      setStartingLocation (profile) {
        this.center = profile.location && profile.location.place_id ? profile.location : this.center
      }
    },
    mounted () {
      this.fetchAllOpenOffers({limit: 200})
      this.setStartingLocation(this.profileInfo)
    },
    components: {
      'app-button': Button
    }
  }
</script>

<style>
  .offer-descriptions {
    height: 600px;
    overflow-y: scroll;
  }
</style>
