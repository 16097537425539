import moment from 'moment'

export default {
  methods: {
    formatDate (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    formatTime (time) {
      return moment(time, 'HH:mm:ss').format('hh:mm A')
    },
    calendarDate (date) {
      const isThisWeek = moment(date).isSame(moment(), 'week')
      const isNextWeek = moment(date).isSame(moment().add(1, 'weeks'), 'week')

      if (moment(date).isSame(moment(), 'day')) {
        return 'Today'
      } else if (moment(date).isBefore(moment(), 'day')) {
        return this.formatDate(date)
      } else if (moment(date).isSame(moment().add(1, 'days'), 'day')) {
        return 'Tomorrow'
      } else if (isThisWeek) {
        return `This ${moment(date).format('dddd')}`
      } else {
        return this.formatDate(date)
      }
    },

    calendarDateTime (date) {
      return moment(date).calendar(null, {
        lastDay: '[Yesterday at] hh:mm A',
        sameDay: '[Today at] hh:mm A',
        lastWeek: '[last] dddd [at] hh:mm A',
        sameElse: moment(date).format('MM/DD/YYYY hh:mm A')
      })
    }
  }
}
