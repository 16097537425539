<template>
  <div class="calendar-body">
    <div class="list-container">
      <div class="list-container__body">
        <div class="list-container-body-head align-center">Job Offers</div>
        <!--<div class="offers-nav">-->
          <!--<div>Paid</div>-->
          <!--<div>Draft</div>-->
        <!--</div>-->
        <div v-for="offer in offersForPage" class="offer-item">
          <div class="offer-item__name">
            {{offer.name}}
          </div>
          <div v-if="offer && offer.company && offer.company.id" class="offer-from">
            This offer from
            <router-link :to="'/companies/' + offer.company.id">
              {{offer.company.name}}
            </router-link>
          </div>
          <div class="offer-item__content">
            <div class="offer-item__detail">
              <ul>
                <li>
                  <span class="icon-ico-40-date"></span>
                  {{formatDate(offer.date)}} - {{formatTime(offer.time)}}
                </li>
                <li><span class="icon-ico-42-money"></span>$ {{offer.price}}</li>
                <li>
                  <div :class="offerItemClass(offer)">
                    <div class="offers-box-item__status">{{getStatusName(offer)}}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="offer-item__info">
              <p class="text-limiter">{{offer.description}}</p>
              <ul>
                <li><span>Type</span>{{offer.offerType.name}}</li>
                <li><span class="icon-ico-43-category"></span>{{offer.category.name}}</li>
                <li v-if="offer.location"><span class="icon-ico-44-location"></span>{{offer.location.formatted_address}}</li>
                <li v-if="offer.conference_link">
                  <span>Conference link</span>
                  <template v-for="(part, index) in parseConferenceLink(offer.conference_link)">
                    <a v-if="part.isUrl" :href="part.text" target="_blank" style="margin-right: 10px; color: #575757">{{ part.text }}</a>
                    <span v-else style="color: #575757">{{ part.text }}</span>
                    <span v-if="index < parseConferenceLink(offer.conference_link).length - 1" style="color: #9d9d9d">|</span>
                  </template>
                </li>
                <li v-if="offer.offerProfile"><span>Freelancer type</span>{{offer.offerProfile.name}}</li>
              </ul>
              <div class="offer-item__button">
                <router-link tag="div" :to="'/myCalendar/myJobOffers/' + offer.id" class="offer-item__button">
                  <app-button title="view" cssClass="btn--default" :slideOnHover="false"/>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-container__bottom">
      <pagination :current-page="currentPage"
                  :total-items="offersTotal"
                  :itemsPerPage="perPage"
                  @page-changed="pageChanged">
      </pagination>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Button from '../../components/Button.vue'
  import Pagination from '../../components/Pagination.vue'
  import DateHelper from '../../helpers/DateHelper'
  import StatusHelper from '../../helpers/StatusHelper'
  import LinkHelper from '@/helpers/LinkHelper'
  import {OFFER_STATUSES} from '@/helpers/Enums'

  export default {
    data () {
      return {
        perPage: 10
      }
    },
    mixins: [DateHelper, StatusHelper, LinkHelper],
    computed: {
      ...mapGetters(['calendarOffers', 'offersTotal', 'myOffersPage']),
      currentPage () {
        return this.$store.state.jobOffer.myOffersPage
      },
      offersForPage () {
        const startIndex = (this.currentPage - 1) * this.perPage
        return this.calendarOffers.slice(startIndex, startIndex + this.perPage)
      }
    },
    methods: {
      offerItemClass (offer) {
        let itemClass = ''
        const errorStatuses = [OFFER_STATUSES.DECLINED, OFFER_STATUSES.CANCELLED]
        if (!offer || !offer.status) console.log('===========', offer)
        if (offer.status && errorStatuses.indexOf(offer.status.id) !== -1) {
          itemClass = 'offers-box-item--declined'
        } else if (Number(offer.status.id) === OFFER_STATUSES.DRAFT_OFFER) {
          itemClass = 'offers-box-item--new'
        }
        return itemClass
      },
      pageChanged (page) {
        this.$store.state.jobOffer.myOffersPage = page
      }
    },
    mounted () {
      this.$store.state.jobOffer.myOffersPage = 1
    },
    components: {
      'app-button': Button,
      'pagination': Pagination
    }
  }
</script>
