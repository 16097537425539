<template>
  <div class="calendar-body">
    <div class="list-container">
      <div class="list-container__body">
        <div class="list-container-body-head align-center">Create an offer</div>
        <div class="create-offer">
          <form action="#" class="js_validation">
            <div :data-error="errors.first('Name')"
                 :class="[this.errorClass('Name'), 'input-field', 'required-field']">
              <div class="label-field">Offer name</div>
              <div class="label-area">
                <input
                  type="text"
                  name="Name"
                  v-model="offer.name"
                  v-validate="'required'"
                >
              </div>
            </div>
            <div :data-error="errors.first('Type')" :class="[this.errorClass('Type'), 'input-field', 'required-field']">
              <div class="label-field">Offer type</div>
              <div class="label-area">
                <app-select
                  name="Type"
                  v-model="offer.offer_type_id"
                  v-validate="'required'"
                >
                  <option value disabled selected></option>
                  <option v-for="type in offerTypes" :value="type.id">{{type.name}}</option>
                </app-select>
              </div>
            </div>
            <div :data-error="errors.first('Description')"
                 :class="[this.errorClass('Description'), 'input-field', 'required-field']">
              <div class="label-field">Offer description</div>
              <div class="label-area">
                    <textarea
                      type="text"
                      class="materialize-textarea"
                      name="Description"
                      v-model="offer.description"
                      v-validate="'required'"
                    ></textarea>
              </div>
            </div>
            <div :data-error="errors.first('Category')" :class="[this.errorClass('Category'), 'input-field', 'required-field']">
              <div class="label-field">Category</div>
              <div class="label-area">
                <app-select
                  name="Category"
                  v-model="offer.category_id"
                  v-validate="'required'"
                >
                  <option value disabled selected>Select the category</option>
                  <option v-for="category in allCategories" :value="category.id">{{category.name}}</option>
                </app-select>
              </div>
            </div>
            <div :data-error="errors.first('Date')" :class="[this.errorClass('Date'), 'input-field required-field input-field--calendar']">
              <div class="label-field">Date</div>
              <div class="label-area">
                <datepicker v-model="offer.date" name="Date" v-validate="'required'" data-vv-validate-on="change"/>
              </div>
            </div>
            <div :data-error="errors.first('Time')" :class="[this.errorClass('Time'), 'input-field required-field']">
              <div class="label-field">Time</div>
              <div class="label-area">
                <input
                  type="time"
                  name="Time"
                  v-model="offer.time"
                  v-validate="'required'"
                >
              </div>
            </div>
            <div :data-error="errors.first('Location')"
                 :class="[this.errorClass('Location'), 'input-field', 'required-field']">
              <div class="label-field">Location</div>
              <div class="label-area">
                <location-input
                  :getLocationData="getLocationData"
                  v-model="offer.location"
                  :value="location"
                  v-validate="'validate_conditional_location:' + offer.conference_link"
                  ref="Location"
                  name="Location"/>
              </div>
            </div>
            <div :data-error="errors.first('Conference link')"
                 :class="[this.errorClass('Conference link'), 'input-field', 'required-field']">
              <div class="label-field">Conference link</div>
              <div class="label-area">
                <input
                  type="text"
                  name="Conference link"
                  v-model="offer.conference_link"
                  v-validate="'required_if:Location,'"
                >
              </div>
            </div>
            <div v-if="!selected_freelancer" :data-error="errors.first('Freelancer Type')"
                 :class="[this.errorClass('Freelancer Type'), 'input-field', 'required-field']">
              <div class="label-field">Type of freelancers</div>
              <div class="label-area">
                <div class="check-box-group">
                  <div class="check-box">
                    <input id="check4" type="radio" value="lawyer" v-model="offer.freelancerType" v-validate="'required'" name="Freelancer Type" class="filled-in">
                    <label for="check4">Lawyers</label>
                  </div>
                  <div class="check-box">
                    <input id="check5" type="radio" value="nonlawyer" v-model="offer.freelancerType" v-validate="'required'" name="Freelancer Type" class="filled-in">
                    <label for="check5">Nonlawyers</label>
                  </div>
                </div>
              </div>
            </div>
            <div :data-error="errors.first('Nonlawyer Type')"
                 :class="[this.errorClass('Nonlawyer Type'), 'input-field', 'required-field']"
                 v-if="offer.freelancerType === 'nonlawyer'">
              <div class="label-field">Type of Nonlawyer</div>
              <div class="label-area">
                <div class="check-box-group">
                  <div class="check-box">
                    <input id="check1" type="radio" value="2" v-model="offer.offer_profile_id" v-validate="'required'" name="Nonlawyer Type" class="filled-in">
                    <label for="check1">Paralegal</label>
                  </div>
                  <div class="check-box">
                    <input id="check2" type="radio" value="3" v-model="offer.offer_profile_id" v-validate="'required'" name="Nonlawyer Type" class="filled-in">
                    <label for="check2">Stenographer</label>
                  </div>
                  <div class="check-box">
                    <input id="check3" type="radio" value="4" v-model="offer.offer_profile_id" v-validate="'required'" name="Nonlawyer Type" class="filled-in">
                    <label for="check3">Expert witness</label>
                  </div>
                </div>
              </div>
            </div>
            <div :data-error="errors.first('Price')"
                 :class="[this.errorClass('Price'), 'input-field', 'required-field']">
              <div class="label-field">Price</div>
              <div class="label-area">
                <input
                  type="text"
                  name="Price"
                  v-model="offer.price"
                  v-validate="{required: true, regex:/^(\d+\.?\d{0,2}|\.\d{1,2})$/ }"
                >
              </div>
            </div>
            <div class="input-field input-field--area">
              <div class="label-field">Add the document</div>
              <div class="label-area">
                <file-dropzone :onFilesChanged="onFilesChanged"/>
              </div>
            </div>
            <spinner v-if="creationProcessing"/>
            <div v-else class="create-offer__button-wrapper">
              <template v-if="selected_freelancer">
                <assigned-freelancer
                  :cssClass="'marginLeft'"
                  :freelancer="selected_freelancer"
                  subtext="Selected Freelancer"
                ></assigned-freelancer>
                <app-button title="To selected Freelancer" :onClick="() => this.selectFreelancer(selected_freelancer)"/>
              </template>
              <template v-if="!selected_freelancer">
                <app-button title="To Job Board" :onClick="createAnOffer" />
                <app-button title="To Freelancer" :onClick="showFreelancersModal" />
              </template>
              <app-button title="Cancel" :onClick="redirectToMyCalendar" />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div id="modal-choose-friend" class="modal">
      <div class="modal-head">Choose a freelancer</div>
      <div class="modal-content">
        <div class="thank-friend">
          <div class="search-friend">
            <div class="input-field search-field">
              <input type="search" placeholder="Find a freelancer" @keyup="filterFreelancersByName">
            </div>
          </div>
          <div class="friend-list">
            <div class="friend" v-for="freelancer in freelancerList" v-if="freelancer.id !== profileInfo.id">
              <div class="friend-col" @click="showFreelancerInNewTab(freelancer)">
                <div v-if="freelancer.image_url"
                    class="friend-icon"
                    id="freelancer-image"
                    :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancer.image_url + ')' }"></div>
                <div v-else class="friend-icon">
                  <img src="../../assets/images/profilePlaceholder.png" alt="">
                </div>
                <div class="friend-name">{{ freelancer.name }} {{ freelancer.lastname }}</div>
              </div>
              <app-button title="Send" :onClick="() => selectFreelancer(freelancer)"/>
            </div>
          </div>
        </div>
        <div>
          <app-button title="Cancel" cssClass="modal-close"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import ImageHelper from '../../helpers/ImageHelper'
  import Button from '../../components/Button.vue'
  import Select from '../../components/Select.vue'
  import FileDropzone from '../../components/FileDropzone.vue'
  import LocationInput from '../../components/LocationInput.vue'
  import Datepicker from '../../components/Datepicker.vue'
  import Spinner from '../../components/Spinner.vue'
  import AssignedFreelancer from './AssignedFreelancer'
  import DateHelper from '../../helpers/DateHelper'
  import moment from 'moment'
  import {swalOptionsForQuestion} from '@/misc/swallOptions'

  export default {
    data () {
      return {
        location: '',
        offer: {
          name: '',
          description: '',
          category_id: null,
          date: null,
          time: null,
          location: '',
          freelancerType: null,
          offer_type_id: null,
          price: '',
          conference_link: ''
        },
        attachments: null,
        roleForSearch: null
      }
    },
    computed: {
      ...mapGetters(['allCategories', 'states', 'locations', 'profileInfo', 'dateFromCalendar', 'freelancerList', 'offerTypes', 'selected_freelancer']),
      creationProcessing () {
        return this.$store.state.jobOffer.isProcessing
      },
      freelancersProcessing () {
        return this.$store.state.freelancer.isProcessing
      },
      offerDateTime () {
        const dateToCreate = moment(this.offer.date).format('YYYY-MM-DD')
        return dateToCreate + ' ' + this.offer.time + ':00'
      }
    },
    mixins: [ImageHelper, DateHelper],
    methods: {
      ...mapActions(['fetchCategories', 'fetchStates', 'fetchLocations', 'createJobOffer', 'fetchOfferTypes',
        'fetchFreelancerList', 'sendToFreelancer', 'offerFromFreelancersCalendar']),
      errorClass (field) {
        return {
          error: this.errors.has(field)
        }
      },
      createAnOffer () {
        this.offer.company_id = this.profileInfo.id
        this.offer.offer_profile_id = this.offer.freelancerType === 'lawyer' ? 1 : this.offer.offer_profile_id
        Promise.all([
          this.$validator.validateAll(),
          //this.$validator.validate('Location', this.offer.location)
        ]).then((result) => {
          if (result.some(valid => !valid)) {
            let err = this.scrollToErrorField()
            if (err) {
              return false
            }
          }

          this.$swal({
            title: 'Please note!',
            text: 'Your Offer will be created now!',
            ...swalOptionsForQuestion
          }).then(() => {
            this.createJobOffer({
              offerInfo: {
                ...this.offer,
                public: 1,
                date: this.offerDateTime
              },
              offerAttachments: this.attachments
            })
          })
        })
      },
      onFilesChanged (files) {
        this.attachments = files.length > 0 ? this.attachments = files : this.attachments = null
      },
      getLocationData (placeResultData) {
        if (typeof placeResultData === 'object' ) {
          this.offer.location = placeResultData
          this.location = placeResultData.formatted_address
        } else {
          this.offer.location = ''
          this.location = ''
        }
      },
      showFreelancersModal () {
        Promise.all([
          this.$validator.validateAll(),
          //this.$validator.validate('Location', this.offer.location)
        ]).then((result) => {
          let err = false
          if (result.some(valid => !valid)) {
            err = this.scrollToErrorField()
            if (err) {
              return false
            }
          }

          if (!err) {
            this.roleForSearch = this.offer.freelancerType === 'lawyer' ? 2 : 3
            this.fetchFreelancerList({role_id: this.roleForSearch, offer_profile_id: this.offer.offer_profile_id})
            window.$('#modal-choose-friend').modal('open')
          }
        })
      },
      showFreelancerInNewTab (freelancer) {
        window.open('/freelancers/' + freelancer.id)
      },
      filterFreelancersByName (e) {
        this.fetchFreelancerList({search: e.target.value, role_id: this.roleForSearch})
      },
      selectFreelancer (freelancer) {
        this.offer.company_id = this.profileInfo.id
        this.offer.offer_profile_id = this.offer.freelancerType === 'lawyer' ? 1 : this.offer.offer_profile_id
        this.offer.date = this.offerDateTime
        this.createJobOffer({offerInfo: this.offer, offerAttachments: this.attachments, sendToFreelancer: freelancer})

        window.$('#modal-choose-friend').modal('close')
      },
      scrollToErrorField () {
        let errors = window.$('.input-field.error')
        if (errors.length) {
          window.$('html, body').animate({
            scrollTop: errors.offset().top - 80
          }, 300)
          return true
        }
      },
      redirectToMyCalendar() {
        this.$router.push({ name: 'myCalendar' })
      }
    },
    components: {
      'app-select': Select,
      'app-button': Button,
      'file-dropzone': FileDropzone,
      'location-input': LocationInput,
      'datepicker': Datepicker,
      'spinner': Spinner,
      'assigned-freelancer': AssignedFreelancer
    },
    mounted () {
      let time = '00:00'
      let _i = this.dateFromCalendar && this.dateFromCalendar.hasOwnProperty('_i') ? this.dateFromCalendar._i : null
      if (_i) {
        let hoursFromCalendar = _i[3].toString()
        let minutesFromCalendar = _i[4].toString()
        time = (hoursFromCalendar.length === 1 ? '0' + hoursFromCalendar : hoursFromCalendar) + ':' +
        (minutesFromCalendar.length === 1 ? '0' + minutesFromCalendar : minutesFromCalendar)
      } else {
        let currentTime = new Date()
        currentTime.setMinutes(currentTime.getMinutes() + 30)
        currentTime.setMinutes(0)
        time = ('0' + currentTime.getHours()).substr(-2) + ':00'
      }

      this.offer.date = this.dateFromCalendar ? this.formatDate(this.dateFromCalendar) : ''
      this.offer.time = time

      this.fetchCategories()
      this.fetchOfferTypes()

      window.$('.modal').modal({
        ready () {
          window.$('.modal-overlay').detach().appendTo('.wrapper')
        }
      })
    },
    created () {
    },
    beforeDestroy () {
      this.offerFromFreelancersCalendar(null)
    }
  }
</script>

<style lang="scss">
  .create-offer {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button,
    input::-webkit-clear-button {
      -webkit-appearance: none;
    }
  }

  #modal-choose-friend {
    z-index: 1007 !important;

    .friend-list {
      max-height: 300px;
      overflow-y: scroll;

      .friend-col {
        cursor: pointer;
      }
    }

    #freelancer-image {
      background-repeat: no-repeat;
      background-position: 50%;
      border-radius: 50%;
      width: 49px;
      height: 49px;
      background-size: cover;
    }
  }
</style>
