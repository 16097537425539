import {OFFER_STATUSES} from '../helpers/Enums'

export default {
  methods: {
    getStatusName (offer) {
      if (offer.status.id === 2 && !offer.public) {
        return 'Private'
      }
      return offer.status.name
    },
    isDeclined (offer) {
      if (offer.id) {
        return offer.status.id === OFFER_STATUSES.DECLINED
      }
    },
    canSetAppearance (offer) {
      if (offer.id) {
        return Number(offer.status.id) === OFFER_STATUSES.OFFER_IS_PAYED
      }
      return false
    },
    canBeStarted (offer) {
      if (offer.id) {
        const offerStatusId = Number(offer.status.id)
        return offerStatusId === OFFER_STATUSES.OFFER_IS_PAYED
      }
      return false
    },
    canBeSentToReview (offer) {
      if (offer.id) {
        const offerStatusId = Number(offer.status.id)
        return offerStatusId === OFFER_STATUSES.OFFER_IN_PROGRESS
      }
      return false
    },
    canRequestToFixReview (offer) {
      if (offer.id) {
        const offerStatusId = Number(offer.status.id)
        return offerStatusId === OFFER_STATUSES.OFFER_IN_REVIEW
      }
      return false
    },
    visiblePendingDialog (offer) {
      if (offer.id) {
        const offerStatusId = Number(offer.status.id)
        return offerStatusId === OFFER_STATUSES.PENDING_ALERT
      }
      return false
    },
    visibleComments (offer) {
      const deniedStatuses = [
        OFFER_STATUSES.DRAFT_OFFER,
        OFFER_STATUSES.OFFER_PUBLISHED
      ]

      if (offer.id) {
        const offerStatusId = Number(offer.status.id)
        return !deniedStatuses.includes(offerStatusId)
      }

      return false
    },
    visibleAddCommentForm (offer) {
      const deniedStatuses = [
        OFFER_STATUSES.SUPPORT_REVIEW,
        OFFER_STATUSES.CANCELLED,
        OFFER_STATUSES.DONE
      ]

      if (offer.id) {
        const offerStatusId = Number(offer.status.id)
        return !deniedStatuses.includes(offerStatusId)
      }

      return false
    },
    canBeDone (offer) {
      if (offer.id) {
        const offerStatusId = Number(offer.status.id)
        return offerStatusId === OFFER_STATUSES.OFFER_IN_REVIEW
      }
      return false
    },
    canBePayed (offer) {
      const allowedStatuses = [
        OFFER_STATUSES.DRAFT_OFFER,
        OFFER_STATUSES.BOOKING_ACCEPTED
      ]

      if (offer.id) {
        return allowedStatuses.includes(offer.status.id) &&
          offer.payment_approve_url &&
          offer.payment_approve_url.length &&
          offer.payment_in_processing !== 1
      }
    },
    canBeCancelled (offer) {
      const allowedStatuses = [
        OFFER_STATUSES.DRAFT_OFFER,
        OFFER_STATUSES.OFFER_PUBLISHED,
        OFFER_STATUSES.BOOKING_ACCEPTED,
        OFFER_STATUSES.DECLINED
      ]

      if (offer.id) {
        return allowedStatuses.includes(offer.status.id) && offer.payment_in_processing !== 1
      }
    },
    isCancelled (offer) {
      if (offer.id) {
        return offer.status.id === OFFER_STATUSES.CANCELLED
      }
    },
    canEditOffer (offer) {
      const allowedStatuses = [
        OFFER_STATUSES.DRAFT_OFFER,
        OFFER_STATUSES.OFFER_PUBLISHED
      ]

      if (offer.id) {
        return allowedStatuses.includes(offer.status.id) &&
          offer.biddedFreelancers.length === 0
      }

      return false
    },
    canLeaveFeedback (offer) {
      const allowedStatuses = [
        OFFER_STATUSES.CANCELLED,
        OFFER_STATUSES.DECLINED,
        OFFER_STATUSES.DONE
      ]

      if (offer.id) {
        return allowedStatuses.includes(offer.status.id)
      }

      return false
    },
    isActive (offer) {
      if (offer.id) {
        const activeStatuses = [
          OFFER_STATUSES.OFFER_PUBLISHED,
          OFFER_STATUSES.BOOKING_ACCEPTED,
          OFFER_STATUSES.OFFER_IN_PROGRESS,
          OFFER_STATUSES.OFFER_IN_REVIEW,
          OFFER_STATUSES.OFFER_IS_PAYED
        ]
        return activeStatuses.includes(offer.status.id)
      }
    }
  }
}
