<template>
  <div>
    <preloader></preloader>
    <landing-page v-if="isLanding" :isAuthenticated="isAuthenticated" />
    <pages v-else :isAuthenticated="isAuthenticated" :isFreelancer="isFreelancer" />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Preloader from './components/layout/Preloader.vue'
import axios from 'axios'
import LandingPage from './pages/Landing/LandingPage'
import Pages from './pages/Pages/Pages'

export default {
  name: 'app',
  components: {
    'preloader': Preloader,
    'landing-page': LandingPage,
    'pages': Pages
  },
  computed: mapGetters(['isAuthenticated', 'isFreelancer', 'isLanding']),
  methods: mapActions(['fetchProfileInfo', 'setIsLanding']),
  mounted () {
    this.$route.name !== 'LandingPage' && this.setIsLanding(false)
    if (this.isAuthenticated) {
      this.fetchProfileInfo()
    }
    axios.interceptors.response.use((response) => {
      return response
    }, (error, res) => {
      if (error.response.status === 401) {
        localStorage.removeItem('suitsin_token')
        this.$store.state.profile.isAuthenticated = false
        this.$router.push('signIn')
      }
      return Promise.reject(error)
    })
  },
  created () {},
  watch: {
    '$route' (to, from) {
      to.name === 'LandingPage' ? this.setIsLanding(true) : this.setIsLanding(false)
    }
  }
}
</script>

<style lang="scss">
  @import "./assets/styles/index.scss";
</style>
