import axios from 'axios'
import ConfigHelper from '../../helpers/ConfigHelper'
import * as types from './mutationTypes'

const BASE_URL = ConfigHelper.get('apiUrl')

const GOOGLE_AUTH = `${BASE_URL}/calendar/google/auth`
const GOOGLE_GET_DATA = `${BASE_URL}/calendar/google/get_data`
const GOOGLE_EXPORT_DATA = `${BASE_URL}/calendar/google/export_data`

const OUTLOOK_AUTH = `${BASE_URL}/calendar/outlook/auth`
// const OUTLOOK_GET_DATA = `${BASE_URL}/calendar/outlook/get_data`

const state = {
  googleAuth: null,
  googleData: null
}

const getters = {
  googleAuth: (state) => state.googleAuth,
  googleData: (state) => state.googleData
}

const actions = {
  /**
   * Google
   */
  fetchGoogleUrlAuth ({commit, state}) {
    return new Promise((resolve, reject) => {
      axios
        .get(GOOGLE_AUTH)
        .then(res => {
          commit(types.FETCH_GOOGLE_AUTH_SUCCESS, res.data.url)
          resolve(res.data.url)
        })
        .catch(error => reject(error))
    })
  },
  fetchGoogleData ({commit, state}, code) {
    return new Promise((resolve, reject) => {
      if (code) {
        axios
          .get(GOOGLE_GET_DATA, {
            params: {
              code
            }
          })
          .then(res => {
            console.log(res)
            commit(types.FETCH_GOOGLE_DATA_SUCCESS, res)
            resolve(res)
          })
          .catch(error => reject(error))
      } else {
        resolve([])
        commit(types.FETCH_GOOGLE_DATA_SUCCESS, [])
      }
    })
  },
  exportDataToGoogle ({commit, state}, code) {
    return new Promise((resolve, reject) => {
      if (code) {
        axios.post(GOOGLE_EXPORT_DATA, { code })
          .then(res => {
            resolve(res)
          })
          .catch(error => reject(error))
      } else {
        resolve([])
      }
    })
  },
  /**
   * Outlook
   */
  fetchOutlookUrlAuth ({commit, state}) {
    return new Promise((resolve, reject) => {
      axios
        .get(OUTLOOK_AUTH)
        .then(res => {
          commit(types.FETCH_GOOGLE_AUTH_SUCCESS, res.data.url)
          resolve(res.data.url)
        })
        .catch(error => reject(error))
    })
  }
}

const mutations = {
  [types.FETCH_GOOGLE_AUTH_SUCCESS] (state, url) {
    state.googleAuth = url
    return state
  },
  [types.FETCH_GOOGLE_DATA_SUCCESS] (state, data) {
    state.googleData = data
    return state
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
