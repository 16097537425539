<template>
  <div class="input-password-container">
    <input :name="name"
           :value="value"
           @input="$emit('input', $event.target.value)"
           :type="showPassword ? 'text' : 'password'" />
    <svg :class="['input-password-eye', { active: showPassword === true }]" @click="toggleType" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 8.74 9.24 6.5 12 6.5C14.76 6.5 17 8.74 17 11.5C17 14.26 14.76 16.5 12 16.5ZM12 8.5C10.34 8.5 9 9.84 9 11.5C9 13.16 10.34 14.5 12 14.5C13.66 14.5 15 13.16 15 11.5C15 9.84 13.66 8.5 12 8.5Z" />
    </svg>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "InputPassword",
  props: {
    name: String,
    value: String,
    type: String,
  },
  data: () => ({
    showPassword: false,
  }),
  methods: {
    toggleType: function () {
      this.showPassword = !this.showPassword
    }
  }
})
</script>

<style scoped lang="scss">
  .input-password-container {
    position: relative;

    input {
      padding-right: 36px;
    }

    .input-password-eye {
      user-select: none;
      fill: #7c7c7c;
      position: absolute;
      cursor: pointer;

      right: 12px;
      top: calc(50% - 12px);

      &.active {
        fill: #000000;
      }
    }
  }
</style>