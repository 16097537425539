<template>
  <div class="wrapper-inner wrapper-inner--grey wrapper-inner--freelancer">
    <div class="row">
      <div class="col l12 s12">
        <div class="job-board-one">
          <div class="event-container">
            <div class="event-box">
              <div class="list-container-body-head">
                {{offer.name}}
                <router-link :to="backButtonPath" class="back right"><span class="icon-ico-37-back"></span>Back</router-link>
              </div>
              <div class="event-top">
                <!--<div class="event-logo"><img src="../../assets/images/company1.png" alt=""><span>{{offer.company.name}}</span></div>-->
                <div v-if="(!offer.freelancer && this.isActive(offer))" class="event-top-button">
                  <app-button v-if="isNotCompany && !alreadyBidded"
                              title="Bid this offer"
                              cssClass="btn--default modal-trigger"
                              :slideOnHover="false"
                              data-target="modal-bid-offer"
                              :onClick="openBidOfferModal"/>
                  <a v-if="alreadyBidded" @click="cancelBid" class="modal-trigger event-button cancel-bid">Cancel bid</a>
                </div>
                <div class="event-list">
                  <ul>
                    <li><span class="icon-ico-40-date"></span>{{formatDate(offer.date)}}</li>
                    <li><span class="icon-ico-41-time"></span>{{formatTime(offer.time)}}</li>
                    <li><span class="icon-ico-42-money"></span>$ {{offer.price}}</li>
                  </ul>
                </div>
              </div>
              <div class="event-body">
                <div class="event-list">
                  <ul>
                    <li v-if="offer.offerType"><span>Type</span>{{offer.offerType.name}}</li>
                    <li v-if="offer.category"><span class="icon-ico-43-category"></span>{{offer.category.name}}</li>
                    <li v-if="offer.location"><span class="icon-ico-44-location"></span>{{offer.location.formatted_address}}</li>
                    <li v-if="offer.conference_link">
                      <span>Conference link</span>
                      <template v-for="(part, index) in parseConferenceLink(offer.conference_link)">
                        <a v-if="part.isUrl" :href="part.text" target="_blank" style="margin-right: 10px; color: #575757">{{ part.text }}</a>
                        <span v-else style="color: #575757">{{ part.text }}</span>
                        <span v-if="index < parseConferenceLink(offer.conference_link).length - 1" style="color: #9d9d9d">|</span>
                      </template>
                    </li>
                    <!--<li><span class="icon-ico-39-thankfriend"></span>Friend: Andrew Andre</li>-->
                    <li v-if="offer.offerProfile"><span>Freelancer type</span>{{offer.offerProfile.name}}</li>
                  </ul>
                </div>
                <div class="event-body-txt">
                  <p>{{offer.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-bid-offer" class="modal">
      <div class="modal-head">Make bid on this offer</div>
      <div class="modal-content">
        <form action="#" @submit.prevent="placeBid">
          <div class="input-field left-align">
            <div class="label-field">&nbsp;</div>
            <div class="label-area">
              <input type="checkbox" id="checkChange" class="filled-in" value="true" v-model="bidData.changePrice">
              <label for="checkChange">Propose another price</label>
            </div>
          </div>
          <div v-if="bidData.changePrice"
              :data-error="errors.first('bidData.price')"
              :class="[{error: this.errors.has('bidData.price')}, 'input-field', 'required-field']"
          >
            <div class="label-field">Price, $</div>
            <div class="label-area">
              <input type="number"
                  step="1"
                  v-validate="`decimal:2|min_value:${bidData.minPrice}`"
                  data-vv-scope="bidData"
                  name="price"
                  v-model.lazy="bidData.price">
            </div>
          </div>
          <div v-if="bidData.changePrice" class="input-field">
            <div class="label-field">Comment</div>
            <div class="label-area">
              <textarea class="materialize-textarea" aria-invalid="false" rows="5" v-model.lazy="bidData.comment"></textarea>
            </div>
          </div>
        </form>
        <div class="modal-content__button-wrapper">
          <app-button title="Make Bid" :onClick.prevent="placeBid"/>
          <app-button title="Cancel" cssClass="modal-close"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import Button from '../../components/Button.vue'
  import StatusHelper from '../../helpers/StatusHelper'
  import LinkHelper from '@/helpers/LinkHelper'
  import DateHelper from '@/helpers/DateHelper'

  const freshBidData = {
    changePrice: false,
    price: '0',
    minPrice: '0',
    comment: ''
  }

  export default {
    mixins: [StatusHelper, LinkHelper, DateHelper],
    data () {
      return {
        bidData: {
          changePrice: false,
          price: '0',
          minPrice: '0',
          comment: ''
        }
      }
    },
    computed: {
      ...mapGetters({
        offer: 'openOfferDetails',
        profileInfo: 'profileInfo',
        lastNotification: 'lastNotification'
      }),
      backButtonPath () {
        const path = this.$route.fullPath
        if (path.indexOf('companies') > 0) {
          return path.substring(0, path.lastIndexOf('/offers'))
        }
        return '/jobBoard'
      },
      isNotCompany () {
        return this.profileInfo.role && this.profileInfo.role.id !== 1
      },
      alreadyBidded () {
        if (this.offer.biddedFreelancers) {
          return !!this.offer.biddedFreelancers.find(freelancer => freelancer.id === this.profileInfo.id)
        }
        return false
      }
    },
    watch: {
      lastNotification: function (notification) {
        if (parseInt(this.$route.params.offer_id) === notification.offer_id) {
          window.Materialize.toast(notification.data.body, 5000)
          this.fetchOpenOfferDetails(this.$route.params.offer_id)
        }
      },
      offer (offerDetails) {
        if (offerDetails) {
          const path = this.$route.fullPath
          this.bidData.price = offerDetails.price
          this.bidData.minPrice = parseInt(offerDetails.price) * 0.5
          // Relocate user to JobBoard
          if (path.includes('jobBoard')) {
            if ((offerDetails.freelancer && offerDetails.freelancer.id) || !this.isActive(offerDetails)) {
              this.$router.push('/jobBoard')
            }
          }
        }
      },
      'bidData.changePrice': function (changePrice) {
        if (!changePrice) this.bidData.price = this.offer.price
      }
    },
    methods: {
      ...mapActions(['fetchOpenOfferDetails', 'openOfferDetailsCancel', 'placeOfferBid', 'cancelOfferBid']),
      openBidOfferModal () {},
      placeBid () {
        if (this.bidData.changePrice) {
          this.$validator.validateAll('bidData').then(result => {
            if (!result) return
            this.placeOfferBid({offer_id: this.offer.id, freelancer_id: this.profileInfo.id, price: this.bidData.price, comment: this.bidData.comment})
            this.bidData = freshBidData
            window.$('.modal').modal('close')
          })
        } else {
          this.placeOfferBid({offer_id: this.offer.id, freelancer_id: this.profileInfo.id, price: null, comment: null})
          window.$('.modal').modal('close')
        }
      },
      cancelBid () {
        this.cancelOfferBid({offer_id: this.offer.id, freelancer_id: this.profileInfo.id})
      }
    },
    components: {
      'app-button': Button
    },
    mounted () {
      this.fetchOpenOfferDetails(this.$route.params.offer_id)
      window.$('.modal').modal({
        ready () {
          window.$('.modal-overlay').detach().appendTo('.wrapper')
        }
      })
    },
    destroyed () {
      this.openOfferDetailsCancel()
    }
  }
</script>

<style lang="scss">
  #modal-bid-offer {
    textarea {
      min-height: 140px !important;
    }
  }

  #freelancer-image {
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    width: 49px;
    height: 49px;
    background-size: cover;
  }

  .cancel-bid {
    cursor: pointer;
  }
</style>
