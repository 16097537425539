const swalOptionsForQuestion = {
  type: 'question',
  showCancelButton: true,
  confirmButtonColor: '#ffffff',
  cancelButtonColor: '#ffffff',
  confirmButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light">OK</button>`,
  cancelButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light cancel">Cancel</button>`
}

const swalOptionsForQuestionWithInput = {
  input: 'textarea',
  showCancelButton: true,
  confirmButtonColor: '#ffffff',
  cancelButtonColor: '#ffffff',
  confirmButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light">OK</button>`,
  cancelButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light cancel">Cancel</button>`
}

export { swalOptionsForQuestion, swalOptionsForQuestionWithInput }
