<template>
  <div class="container content terms-conditions">
    <header class="header clearfix">
      <div class="container">
        <div class="logo">
          <router-link to="/"><img src="../../assets/images/logo.svg" alt=""></router-link>
        </div>
      </div>
    </header>
    <p style="margin-top: 20px"><span style="color: rgb(33, 33, 33);"><span><b style="font-size: x-large;">Terms of Service</b></span></span></p>
    <p>This agreement is made between you ("Employee") and SuitsIn Inc. on today’s date.</p>
    <p>Employee will perform services for SuitsIn Inc. that may require SuitsIn Inc. to disclose confidential and proprietary information ("Confidential Information") to Employee. (Confidential Information is information and data of any kind, whether written, oral or electronically communicated, that is disclosed or provided to the Employee under this agreement concerning any matters affecting or relating to SuitsIn Inc., the business or operations of SuitsIn Inc., and/or the products, drawings, plans, processes, or other data of SuitsIn Inc. not generally known or available outside of the company.)</p>
    <p>Accordingly, to protect the Confidential Information that will be disclosed during employment, the Employee agrees as follows:</p>
    <ul>
      <li>
        <p>Employee will hold the Confidential Information received from SuitsIn Inc. in strict confidence and will exercise a reasonable degree of care to prevent disclosure to others.</p>
      </li>
      <li>
        <p>Employee will not disclose or divulge either directly or indirectly the Confidential Information to others unless first authorized to do so in writing by SuitsIn Inc. management.</p>
      </li>
      <li>
        <p>Employee will not reproduce the Confidential Information nor use this information commercially or for any purpose other than the performance of his/her duties for SuitsIn Inc..</p>
      </li>
      <li>
        <p>Employee will, upon request or upon termination of his/her relationship with SuitsIn Inc., deliver to SuitsIn Inc. any drawings, notes, documents, equipment, and materials received from SuitsIn Inc. or originating from employment with SuitsIn Inc..</p>
      </li>
      <li>
        <p>SuitsIn Inc. will have the sole right to determine the treatment of all inventions, writings, ideas, and discoveries received from Employee during the period of employment with SuitsIn Inc., including the right to keep the same as a trade secret, to use and disclose the same without prior patent applications, to file copyright registrations in its own name, or to follow any other procedure as SuitsIn Inc. may deem appropriate.</p>
      </li>
      <li>
        <p>SuitsIn Inc. reserves the right to take disciplinary action, up to and including termination, for violations of this agreement in addition to pursuing civil or criminal penalties.</p>
      </li>
      <li>
        <p>This agreement will be interpreted under and governed by the laws of the state of New York.</p>
      </li>
      <li>
        <p>All provisions of this agreement will be applicable only to the extent that they do not violate any applicable law and are intended to be limited to the extent necessary so that they will not render this agreement invalid, illegal, or unenforceable. If any provision of this agreement or any application thereof will be held to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of other provisions of this agreement or of any other application of such provision will in no way be affected thereby.</p>
      </li>
    </ul>
    <p><span style="color: rgb(33, 33, 33);"><span><b style="font-size: large;">Immunity from Liability for Confidential Disclosure of a Trade Secret to the Government or in a Court Filing:</b></span></span></p>
    <p>(1) Immunity—An individual will not be held criminally or civilly liable under any federal or state trade secret law for the disclosure of a trade secret that (A) is made (i) in confidence to a federal, state or local government official, either directly or indirectly, or to an attorney and (ii) solely for the purpose of reporting or investigating a suspected violation of law or (B) is made in a complaint or other document filed in a lawsuit or other proceeding, if such filing is made under seal</p>
    <p>(2) Use of Trade Secret Information in Anti-Retaliation Lawsuit—An individual who files a lawsuit for retaliation by an employer for reporting a suspected violation of law may disclose the trade secret to the attorney of the individual and use the trade secret information in the court proceeding, if the individual (A) files any document containing the trade secret under seal and (B) does not disclose the trade secret, except pursuant to court order.</p>
    <p>Employee represents and warrants that he or she is not under any pre-existing obligations inconsistent with the provisions of this agreement.</p>
    <p>Signing below signifies that the Employee agrees to the terms and conditions of the agreement stated above.</p>
  </div>
</template>

<script>
export default {}
</script>

<style>
  ul, li {
    font-size: 16px !important;
    line-height: 24px !important;
    @media (min-width: 1200px) {
      font-size: 18px !important;
      line-height: 32px !important;
    }
  }
</style>
