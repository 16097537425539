<template>
  <div class="wrapper-inner">
    <div class="calendar-container">
      <div class="calendar-nav">
        <ul>
          <router-link tag="li" activeClass="active" :to="{name: 'myCalendar'}" :exact="true">
            <a><span class="icon-ico-33"></span>My Calendar</a>
          </router-link>
          <router-link tag="li" activeClass="active" :to="{name: 'myJobOffers'}" :exact="true">
            <a><span class="icon-ico-33"></span>Job Offers</a>
          </router-link>
          <router-link tag="li" activeClass="active" :to="{name: 'jobOffersInWork'}">
            <a><span class="icon-ico-33"></span>My active jobs</a>
          </router-link>
        </ul>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    computed: {
      ...mapGetters(['profileInfo'])
    },
    watch: {
      profileInfo: function (profile) {
        this.fetchCalendarOffers(profile)
      }
    },
    methods: {
      ...mapActions(['fetchCalendarOffers'])
    },
    mounted () {
      if (this.profileInfo.id) {
        this.fetchCalendarOffers(this.profileInfo)
      }
    }
  }
</script>
