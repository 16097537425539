<template>
  <div class="general-top-navigation">
    <ul>
      <li><router-link activeClass="active" :to="{name: 'dashboard'}">Dashboard</router-link></li>
      <li v-if="isFreelancer"><router-link activeClass="active" :to="{name: 'companyList'}">Companies</router-link></li>
      <li v-else><router-link activeClass="active" :to="{name: 'freelancerList'}">Freelancers</router-link></li>
      <li><router-link activeClass="active" :to="{name: 'myCalendar'}">My Calendar</router-link></li>
      <li><router-link activeClass="active" :to="{name: 'openOffers'}">Job Board</router-link></li>
      <li><router-link activeClass="active" :to="{name: 'profile'}">My Profile</router-link></li>
    </ul>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    computed: {
      ...mapGetters(['isFreelancer'])
    }
  }
</script>

<style scoped lang="scss">
.general-top-navigation {
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  margin: 0;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);

  ul {
    display: inline-flex;
    height: 40px;

    li {
      a {
        padding: 0 5px;
        font-size: 10px;

        @media (min-width: 360px) {
          font-size: 12px;
        }

        @media (min-width: 460px) {
          font-size: 14px;
        }

        @media (min-width: 760px) {
          font-size: 18px;
        }
      }
    }
  }
}
</style>

