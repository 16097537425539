export const COMPANY_REWIEWS_FETCH_PROCESSING = 'COMPANY_REWIEWS_FETCH_PROCESSING'
export const COMPANY_REWIEWS_FETCH_SUCCESS = 'COMPANY_REWIEWS_FETCH_SUCCESS'
export const COMPANY_REWIEWS_FETCH_ERROR = 'COMPANY_REWIEWS_FETCH_ERROR'
export const COMPANY_DETAILS_CANCEL = 'COMPANY_DETAILS_CANCEL'

export const COMPANY_LIST_FETCH_PROCESSING = 'COMPANY_LIST_FETCH_PROCESSING'
export const COMPANY_LIST_FETCH_SUCCESS = 'COMPANY_LIST_FETCH_SUCCESS'
export const COMPANY_LIST_FETCH_ERROR = 'COMPANY_LIST_FETCH_ERROR'

export const COMPANY_DETAILS_FETCH_PROCESSING = 'COMPANY_DETAILS_FETCH_PROCESSING'
export const COMPANY_DETAILS_FETCH_SUCCESS = 'COMPANY_DETAILS_FETCH_SUCCESS'
export const COMPANY_DETAILS_FETCH_ERROR = 'COMPANY_DETAILS_FETCH_ERROR'

export const COMPANY_OFFERS_FETCH_PROCESSING = 'COMPANY_OFFERS_FETCH_PROCESSING'
export const COMPANY_OFFERS_FETCH_SUCCESS = 'COMPANY_OFFERS_FETCH_SUCCESS'
export const COMPANY_OFFERS_FETCH_ERROR = 'COMPANY_OFFERS_FETCH_ERROR'

export const COMPANY_ADD_RATING_PROCESSING = 'COMPANY_ADD_RATING_PROCESSING'
export const COMPANY_ADD_RATING_SUCCESS = 'COMPANY_ADD_RATING_SUCCESS'
export const COMPANY_ADD_RATING_ERROR = 'COMPANY_ADD_RATING_ERROR'
