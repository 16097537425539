import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex'
import VeeValidate  from './plugins/validator'
import VueMeta from 'vue-meta'

import VueSweetAlert from 'vue-sweetalert'
import * as VueGoogleMaps from 'vue2-google-maps'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

window.Pusher = Pusher

Vue.use(VueMeta)
Vue.use(VeeValidate)
Vue.use(VueSweetAlert)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    version: '3.2',
    libraries: 'places',
    language: 'en'
  }
})

const echoOptions = {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true
}

if (localStorage.getItem('suitsin_token')) {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('suitsin_token')

  window.Echo = new Echo({
    ...echoOptions,
    client: new Pusher(echoOptions.key, {
      ...echoOptions,
      channelAuthorization: {
        endpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
        headers: {
          authorization: localStorage.getItem('suitsin_token')
        }
      }
    })
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  metaInfo: {
    title: 'SuitsIn - Law Professionals Marketplace',
    meta: [
      {
        name: 'description',
        content: 'SuitsIn - Law Professionals Marketplace'
      }
    ]
  },
  render: h => h(App)
}).$mount('#app')
