<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col l4 m4 s12">
          <!-- <ul class="foot-nav navigation-hover-menu">
            <li><a href="#">Payment info</a></li>
            <li><a href="#">Referral info</a></li>
          </ul> -->
        </div>
        <div class="col l4 m4 s12 align-center">
          <div class="copyright">
            &#169;
            SuitsIn, <span class="yearN">{{ yearNow }}</span>
          </div>
        </div>
<!--        <div class="col l4 m4 s12">-->
<!--          <ul class="social">-->
<!--            <li>-->
<!--              <a href="https://www.facebook.com" target="_blank" title="Facebook">-->
<!--                <span class="icon-ico-11"></span>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li><a href="#" target="_blank"><span class="icon-ico-12"></span></a></li>-->
<!--            <li>-->
<!--              <a href="https://www.instagram.com" target="_blank" title="Instagram">-->
<!--                <span class="icon-ico-13"></span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
      </div>
    </div>
  </footer>
</template>


<script>
  export default {
    data () {
      return {
        yearNow: ''
      }
    },
    mounted () {
      this.yearNow = new Date().getFullYear().toString()
    }
  }
</script>
