<template>
  <vue-dropzone
    id="myVueDropzone"
    ref="fileDropzone"
    url="http://required-for-some-reason.ugh"
    :autoProcessQueue="false"
    :maxFileSizeInMB='50'
    acceptedFileTypes="image/bmp,image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document/application/excel,application/vnd.ms-excel"
    v-on:vdropzone-files-added="onFileAdd"
    v-on:vdropzone-removed-file="onFileRemoved"
    v-on:vdropzone-error="onFileUploadError"
  ></vue-dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
export default {
  props: {
    onFilesChanged: {
      type: Function,
      required: true
    },
    existFiles: Array
  },
  watch: {
    existFiles (files) {
      files.forEach(file => {
        let data = { size: 123, name: file.name, type: 'image/png' }
        this.$refs.fileDropzone.manuallyAddFile(data, file.url)
      })
    }
  },
  methods: {
    onFileAdd (file) {
      this.onFilesChanged(this.$refs.fileDropzone.dropzone.files)
    },
    onFileRemoved (file) {
      this.onFilesChanged(this.$refs.fileDropzone.dropzone.files)
    },
    onFileUploadError (file, err) {
      if (err.indexOf('File is too big') !== -1) {
        window.Materialize.toast(`Unable to upload ${file.name}. ${err}`, 4000)
      }
      this.$refs.fileDropzone.dropzone.removeFile(file)
    }
  },
  components: {
    'vueDropzone': vue2Dropzone
  }
}

</script>

<style>
.dz-progress {
  display: none;
}
.dz-details {
  background-color: #d0ab24 !important;
  color: #272727 !important;
}
.dz-remove {
  color: #272727 !important;
  border-color: #272727 !important;
}
.dropzone.dz-clickable {
  position: relative;
}
.dropzone.dz-clickable:before {
  content: 'DRAG & DROP \A or click this area';
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  right: 30px;
  font-size: 30px;
  line-height: 40px;
  color: #000;
}

.dropzone.dz-started.dz-clickable:before {
  content: '';
}
</style>
