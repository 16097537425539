<template>
  <canvas height="200px" width="350px" id="signature-tool"></canvas>
</template>

<script>
  import SignaturePad from 'signature_pad'
  export default {
    mounted () {
      const canvas = document.querySelector('#signature-tool')
      return new SignaturePad(canvas)
    }
  }
</script>

<style>
  canvas {
    display: inline-block;
    cursor: pointer;
    border: 1px dashed #c6c6c6;
  }
</style>
