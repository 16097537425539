export const FREELANCER_REWIEWS_FETCH_PROCESSING = 'FREELANCER_REWIEWS_FETCH_PROCESSING'
export const FREELANCER_REWIEWS_FETCH_SUCCESS = 'FREELANCER_REWIEWS_FETCH_SUCCESS'
export const FREELANCER_REWIEWS_FETCH_ERROR = 'FREELANCER_REWIEWS_FETCH_ERROR'

export const FREELANCER_LIST_FETCH_PROCESSING = 'FREELANCER_LIST_FETCH_PROCESSING'
export const FREELANCER_LIST_FETCH_SUCCESS = 'FREELANCER_LIST_FETCH_SUCCESS'
export const FREELANCER_LIST_FETCH_ERROR = 'FREELANCER_LIST_FETCH_ERROR'

export const FREELANCER_LIST_SET_FILTERS = 'FREELANCER_LIST_SET_FILTERS'

export const FREELANCER_DETAILS_FETCH_PROCESSING = 'FREELANCER_DETAILS_FETCH_PROCESSING'
export const FREELANCER_DETAILS_FETCH_SUCCESS = 'FREELANCER_DETAILS_FETCH_SUCCESS'
export const FREELANCER_DETAILS_FETCH_ERROR = 'FREELANCER_LIST_FETCH_ERROR'
export const FREELANCER_DETAILS_CANCEL = 'FREELANCER_LIST_CANCEL'

export const FREELANCER_OFFERS_FETCH_PROCESSING = 'FREELANCER_OFFERS_FETCH_PROCESSING'
export const FREELANCER_OFFERS_FETCH_SUCCESS = 'FREELANCER_OFFERS_FETCH_SUCCESS'
export const FREELANCER_OFFERS_FETCH_ERROR = 'FREELANCER_OFFERS_FETCH_ERROR'

export const FREELANCER_ADD_RATING_PROCESSING = 'FREELANCER_ADD_RATING_PROCESSING'
export const FREELANCER_ADD_RATING_SUCCESS = 'FREELANCER_ADD_RATING_SUCCESS'
export const FREELANCER_ADD_RATING_ERROR = 'FREELANCER_ADD_RATING_ERROR'
