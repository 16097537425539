<template>
  <div class="wrapper-inner">
    <div class="general-navigation">
      <ul>
        <router-link tag="li" activeClass="active" :to="{name: 'profile'}" exact>
          <span class="icon-ico-21"></span>Info
        </router-link>
        <router-link tag="li" activeClass="active" :to="{name: 'updateCreds'}">
          <span class="icon-ico-22"></span>Change password / E-mail
        </router-link>
        <router-link tag="li" activeClass="active" :to="{name: 'paymentHistory'}">
          <span class="icon-ico-24"></span>Payment history
        </router-link>
        <router-link tag="li" activeClass="active" :to="{name: 'reviews'}">
          Reviews
        </router-link>
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  export default {
    computed: mapGetters(['profileInfo']),
    methods: mapActions(['fetchProfileInfo', 'fetchStates', 'fetchLocations', 'fetchCategories']),
    mounted () {
      Promise.all([this.fetchStates()]).then(() => {
        this.fetchCategories()
      })
    }
  }
</script>
