<template>
  <button :type="type"
          @mouseenter="mouseEnter"
          @mouseleave="mouseLeave"
          @click="clickEvt"
          :class="['prev-hover btn waves-effect waves-light', this.cssClass]">

    {{title}}<span class="bg"></span>

    <span class="btn-tlt">{{title}}</span>
  </button>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'submit'
      },
      title: {
        type: String,
        required: true
      },
      onClick: {
        type: Function
      },
      cssClass: {
        type: String
      },
      slideOnHover: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      clickEvt (e) {
        if (this.onClick) {
          e.preventDefault()
          this.onClick()
        }
      },
      mouseEnter () {
        if (!this.slideOnHover) return
        let btnStyles = this.$el.classList
        btnStyles.add('hover')
        btnStyles.remove('prev-hover')
      },
      mouseLeave () {
        if (!this.slideOnHover) return
        let btnStyles = this.$el.classList
        btnStyles.remove('hover')
        btnStyles.add('un-hover')
        setTimeout(() => {
          btnStyles.remove('un-hover')
        }, 600)
        setTimeout(() => {
          btnStyles.add('prev-hover')
        }, 650)
      }
    }
  }
</script>

<style scoped>
  button {
    white-space: nowrap;
  }
</style>
