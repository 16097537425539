export const PROFILE_INFO_FETCH_PROCESSING = 'PROFILE_INFO_FETCH_PROCESSING'
export const PROFILE_INFO_FETCH_SUCCESS = 'PROFILE_INFO_FETCH_SUCCESS'
export const PROFILE_INFO_SET_COMPLETED = 'PROFILE_INFO_SET_COMPLETED'
export const PROFILE_INFO_FETCH_ERROR = 'PROFILE_INFO_FETCH_ERROR'

export const ACTIVATE_SOCKET_SUBSCRIPTION = 'ACTIVATE_SOCKET_SUBSCRIPTION'
export const DEACTIVATE_SOCKET_SUBSCRIPTION = 'DEACTIVATE_SOCKET_SUBSCRIPTION'

export const NOTIFICATIONS_FETCH_PROCESSING = 'NOTIFICATIONS_FETCH_PROCESSING'
export const NOTIFICATIONS_FETCH_SUCCESS = 'NOTIFICATIONS_FETCH_SUCCESS'
export const NOTIFICATIONS_UNREAD_FETCH_SUCCESS = 'NOTIFICATIONS_UNREAD_FETCH_SUCCESS'
export const NOTIFICATIONS_FETCH_ERROR = 'NOTIFICATIONS_FETCH_ERROR'
export const NOTIFICATIONS_ADD_BOTH = 'NOTIFICATIONS_ADD_BOTH'
export const NOTIFICATIONS_SET_LAST = 'NOTIFICATIONS_SET_LAST'
export const NOTIFICATIONS_REMOVE_FROM_UNREAD = 'NOTIFICATIONS_REMOVE_FROM_UNREAD'
export const NOTIFICATIONS_ADD_IGNORE_OFFERS = 'NOTIFICATIONS_ADD_IGNORE_OFFERS'
export const NOTIFICATIONS_REMOVE_IGNORE_OFFERS = 'NOTIFICATIONS_REMOVE_IGNORE_OFFERS'
export const NOTIFICATIONS_ADD_IGNORE_TO_BELL = 'NOTIFICATIONS_ADD_IGNORE_TO_BELL'
export const NOTIFICATIONS_REMOVE_IGNORE_TO_BELL = 'NOTIFICATIONS_REMOVE_IGNORE_TO_BELL'

export const PROFILE_INFO_UPDATE_PROCESSING = 'PROFILE_INFO_UPDATE_PROCESSING'
export const PROFILE_INFO_UPDATE_SUCCESS = 'PROFILE_INFO_UPDATE_SUCCESS'
export const PROFILE_INFO_UPDATE_ERROR = 'PROFILE_INFO_UPDATE_ERROR'

export const PROFILE_CALENDAR_SYNCED_SUCCESS = 'PROFILE_CALENDAR_SYNCED_SUCCESS'

export const PROFILE_IMAGE_UPDATE_PROCESSING = 'PROFILE_IMAGE_UPDATE_PROCESSING'
export const PROFILE_IMAGE_UPDATE_SUCCESS = 'PROFILE_IMAGE_UPDATE_SUCCESS'
export const PROFILE_IMAGE_UPDATE_ERROR = 'PROFILE_IMAGE_UPDATE_ERROR'

export const PROFILE_SIGNATURE_UPDATE_PROCESSING = 'PROFILE_SIGNATURE_UPDATE_PROCESSING'
export const PROFILE_SIGNATURE_UPDATE_SUCCESS = 'PROFILE_SIGNATURE_UPDATE_SUCCESS'
export const PROFILE_SIGNATURE_UPDATE_ERROR = 'PROFILE_SIGNATURE_UPDATE_ERROR'

export const PROFILE_ADD_CATEGORY_PROCESSING = 'PROFILE_ADD_CATEGORY_PROCESSING'
export const PROFILE_ADD_CATEGORY_SUCCESS = 'PROFILE_ADD_CATEGORY_SUCCESS'
export const PROFILE_ADD_CATEGORY_ERROR = 'PROFILE_ADD_CATEGORY_ERROR'
export const PROFILE_REMOVE_CATEGORY_PROCESSING = 'PROFILE_REMOVE_CATEGORY_PROCESSING'
export const PROFILE_REMOVE_CATEGORY_SUCCESS = 'PROFILE_REMOVE_CATEGORY_SUCCESS'
export const PROFILE_REMOVE_CATEGORY_ERROR = 'PROFILE_REMOVE_CATEGORY_ERROR'

export const PROFILE_ADD_PERSONAL_SKILL_PROCESSING = 'PROFILE_ADD_PERSONAL_SKILL_PROCESSING'
export const PROFILE_ADD_PERSONAL_SKILL_SUCCESS = 'PROFILE_ADD_PERSONAL_SKILL_SUCCESS'
export const PROFILE_ADD_PERSONAL_SKILL_ERROR = 'PROFILE_ADD_PERSONAL_SKILL_ERROR'
export const PROFILE_REMOVE_PERSONAL_SKILL_PROCESSING = 'PROFILE_REMOVE_PERSONAL_SKILL_PROCESSING'
export const PROFILE_REMOVE_PERSONAL_SKILL_SUCCESS = 'PROFILE_REMOVE_PERSONAL_SKILL_SUCCESS'
export const PROFILE_REMOVE_PERSONAL_SKILL_ERROR = 'PROFILE_REMOVE_PERSONAL_SKILL_ERROR'

export const PROFILE_CHANGE_PASSWORD_PROCESSING = 'PROFILE_CHANGE_PASSWORD_PROCESSING'
export const PROFILE_CHANGE_PASSWORD_SUCCESS = 'PROFILE_CHANGE_PASSWORD_SUCCESS'
export const PROFILE_CHANGE_PASSWORD_ERROR = 'PROFILE_CHANGE_PASSWORD_ERROR'

export const PROFILE_PAYMENT_HISTORY_FETCH_PROCESSING = 'PROFILE_PAYMENT_HISTORY_FETCH_PROCESSING'
export const PROFILE_PAYMENT_HISTORY_FETCH_SUCCESS = 'PROFILE_PAYMENT_HISTORY_FETCH_SUCCESS'
export const PROFILE_PAYMENT_HISTORY_FETCH_ERROR = 'PROFILE_PAYMENT_HISTORY_FETCH_ERROR'

export const USER_AUTH_PROCESSING = 'USER_AUTH_PROCESSING'
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS'
export const USER_SIGN_IN_ERROR = 'USER_SIGN_IN_ERROR'

export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR'

export const USER_LOGOUT = 'USER_LOGOUT'

export const CANCEL_ERRORS = 'CANCEL_ERRORS'

export const RESET_PASSWORD = 'RESET_PASSWORD'

export const IS_LANDING = 'IS_LANDING'
