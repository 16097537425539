<template>
  <div id="modal-add-rating" class="modal">
    <div v-if="ratedUser" class="modal-head">Rate {{ratedUser.name}}</div>
    <div class="modal-content">
      <form @submit.prevent="sendRewiev">
        <div class="rating">
          <star-rating v-bind:increment="0.5" :max-rating="5" @rating-selected="addRating"></star-rating>
        </div>
        <div class="input-field required-field">
          <textarea class="materialize-textarea" v-model="feedbackComm" placeholder="Comment" aria-invalid="false" rows="15"></textarea>
        </div>
        <div class="modal-content__button-wrapper">
          <app-button title="Send" :onClick.prevent="sendRewiev" />
          <app-button title="Cancel" cssClass="modal-close"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import StarRating from 'vue-star-rating'
  import RolesHelper from '../helpers/RolesHelper'
  import Button from '../components/Button.vue'
  export default {
    data () {
      return {
        feedbackComm: '',
        rating: ''
      }
    },
    props: {
      ratedUser: {
        type: Object,
        default: () => { return {} }
      },
      offer: {
        type: Object,
        required: true
      }
    },
    mixins: [RolesHelper],
    methods: {
      ...mapActions(['addCompanyRating', 'addFreelancerRating']),
      sendRewiev () {
        console.log(this.offer.freelancer.id)
        if (this.isCompany(this.ratedUser)) {
          this.addCompanyRating({company_id: this.ratedUser.id, freelancer_id: this.offer.freelancer.id, offer_id: this.offer.id, reason: this.feedbackComm, rating: this.rating})
          window.$('.modal').modal('close')
        } else {
          this.addFreelancerRating({freelancer_id: this.ratedUser.id, company_id: this.offer.company.id, offer_id: this.offer.id, reason: this.feedbackComm, rating: this.rating})
          window.$('.modal').modal('close')
        }
      },
      addRating (rating) {
        this.rating = rating
      }
    },
    components: {
      'app-button': Button,
      StarRating
    }
  }
</script>
<style>
  .rating {
    text-align: center;
    margin-bottom: 30px;
  }
  .vue-star-rating {
    display: inline-block !important;
  }
  .vue-star-rating-rating-text[data-v-34cbeed1] {
    display: none !important;
  }
  #modal-add-rating textarea {
    min-height: 100px !important;
    padding: 15px;
  }
</style>
