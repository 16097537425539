<template>
  <div>
    <div class="calendar-group">
      <div class="calendar-group-col">
        <div class="mini-calendar">
          <div class="datepicker-date">
            <div id="mini-calendar"></div>
          </div>
        </div>
      </div>
      <div class="calendar-group-col">
        <div class="general-calendar">
          <div id="calendar" class="big-calendar"></div>
        </div>
      </div>
      <div class="calendar-group-col">
        <div class="offers-box">
          <div v-if="filteredOffers" class="offers-box__head">My job offers</div>
          <div class="offers-box-over">
            <router-link
              tag="div"
              v-for="offer in filteredOffers"
              v-if="offer.status !== null"
              :to="'/myCalendar/myJobOffers/' + offer.id"
              :key="offer.id"
              :class="offerItemClass(offer)">
              <div class="offers-box-item__head clearfix">
                <div class="offers-box-item__status">{{getStatusName(offer)}}</div>
                <div class="offers-box-item__date">{{calendarDate(offer.date)}}</div>
              </div>
              <div class="offers-box-item__info">
                <b class="text-limiter">{{offer.title}}</b>
                <div class="offers-box-item__content">
                  <ul>
                    <li><span class="icon-ico-40-date"></span>{{formatDate(offer.date)}}</li>
                    <li><span class="icon-ico-41-time"></span>{{formatTime(offer.time)}}</li>
                    <li><span class="icon-ico-42-money"></span>$ {{offer.price}}</li>
                  </ul>
                  <div class="offers-box-item__box text-limiter">{{offer.description}}</div>
                </div>
                <div class="offer_status">{{offer.public ? 'Public' : 'Private'}}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <sync-calendar></sync-calendar>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import DateHelper from '../../helpers/DateHelper'
  import RolesHelper from '../../helpers/RolesHelper'
  import {OFFER_STATUSES} from '../../helpers/Enums'
  import SyncCalendar from './SyncCalendar.vue'
  import StatusHelper from '../../helpers/StatusHelper'
  import moment from 'moment'

  export default {
    components: {
      'sync-calendar': SyncCalendar
    },
    props: {
      selectedDate: String
    },
    data () {
      return {
        mainCalendar: null,
        miniCalendar: null,
        filteredOffers: null
      }
    },
    mixins: [DateHelper, RolesHelper, StatusHelper],
    computed: {
      ...mapGetters(['calendarOffers', 'lastNotification', 'profileInfo'])
    },
    watch: {
      calendarOffers: function (value) {
        this.putOffersToCalendar(value)
      },
      lastNotification: function (notification) {
        window.Materialize.toast(notification.data.body, 5000)
        this.fetchCalendarOffers(this.profileInfo)
      }
    },
    methods: {
      ...mapActions(['fetchProfileInfo', 'fetchCalendarOffers', 'setDateFromCalendar']),
      putOffersToCalendar (offersForCalendar) {
        const events = [...offersForCalendar].map(event => {
          event.date = !event.date.includes('T') ? `${event.date}T${event.time}` : event.date
          return event
        })
        this.updateCalendar(events)

        const viewData = this.mainCalendar.fullCalendar('getView')
        this.filteredOffers = events.filter(event => {
          return (event.date >= viewData.start.format('YYYY-MM-DD') && viewData.end.format('YYYY-MM-DD') > event.date)
        })
      },
      openDate (date) {
        const currentView = this.mainCalendar.fullCalendar('getView')
        if (currentView.type === 'month') {
          this.mainCalendar.fullCalendar('changeView', 'agendaWeek', date)
          this.mainCalendar.fullCalendar('gotoDate', date)
        } else {
          this.mainCalendar.fullCalendar('changeView', 'agendaDay', date)
          this.mainCalendar.fullCalendar('gotoDate', date)
        }
      },
      updateCalendar (events) {
        this.mainCalendar.fullCalendar('removeEvents')
        this.mainCalendar.fullCalendar('addEventSource', events)
        // this.miniCalendar.fullCalendar('removeEvents')
        // this.miniCalendar.fullCalendar('addEventSource', events)
      },
      offerItemClass (offer) {
        const errorStatuses = [OFFER_STATUSES.DECLINED, OFFER_STATUSES.CANCELLED]
        if (!offer || !offer.status) console.log('===========', offer)
        if (offer.status && errorStatuses.indexOf(offer.status.id) !== -1) {
          return 'offers-box-item offers-box-item--declined'
        } else if (Number(offer.status.id) === OFFER_STATUSES.DRAFT_OFFER) {
          return 'offers-box-item offers-box-item--new'
        } else {
          return 'offers-box-item'
        }
      },
      isAllowedToView (event, currentProfile) {
        const isAssigned = event.freelancer && event.freelancer.id === currentProfile.id
        const isInvited = event.invitedFreelancer && event.invitedFreelancer.id === currentProfile.id
        return isAssigned || isInvited
      },
      onResize () {
        window.syncCalendarDates()
      }
    },
    mounted () {
      this.mainCalendar = window.$('#calendar').fullCalendar({
        header: {
          left: 'agendaDay agendaWeek month', // prev,next
          center: 'prev title next',
          right: ''
        },
        timeZone: 'local',
        fixedWeekCount: false,
        navLinks: true,
        editable: false,
        displayEventTime: false,
        eventLimit: true,
        events: this.events,
        eventClick: (event) => {
          if (this.isCompany(this.profileInfo)) {
            this.$router.push('/myCalendar/myJobOffers/' + event.id)
          } else if (this.isFreelancerProf(this.profileInfo) && (this.isAllowedToView(event, this.profileInfo))) {
            this.$router.push('/myCalendar/myJobOffers/' + event.id)
          } else {
            this.$router.push('/jobBoard/' + event.id)
          }
        },
        dayClick: (date, jsEvent, view, resourceObj) => {
          if (this.isCompany(this.profileInfo)) {
            let dateToCompare = date.clone().utc().unix()
            let today = moment().utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}).unix()
            let tomorrow = moment().utc().add(1, 'day').set({hour: 0, minute: 0, second: 0, millisecond: 0}).unix()
            if (dateToCompare < today) {
              this.$swal({
                title: 'Can`t create a new offer in the past',
                confirmButtonColor: '#ffffff',
                cancelButtonColor: '#ffffff',
                confirmButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light">OK</button>`
              })
            } else if (dateToCompare < tomorrow) {
              this.$swal({
                title: 'Can`t create a new offer for today',
                confirmButtonColor: '#ffffff',
                cancelButtonColor: '#ffffff',
                confirmButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light">OK</button>`
              })
            } else {
              this.$swal({
                title: 'Create a new offer?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#ffffff',
                cancelButtonColor: '#ffffff',
                confirmButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light">OK</button>`,
                cancelButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light cancel">Cancel</button>`
              }).then(() => {
                this.setDateFromCalendar(date)
                this.$router.push({name: 'offerCreation'})
              }).catch(() => {})
            }
          }
        },
        viewRender: (view, element) => {
          this.filteredOffers = window.$(this.mainCalendar).fullCalendar('clientEvents', (event) => {
            return (event.start >= view.start && view.end > event.start)
          })
        },
        eventRender: (event, element) => {
          if (event.status) {
            const warningStatuses = [OFFER_STATUSES.DECLINED, OFFER_STATUSES.CANCELLED]
            if (warningStatuses.indexOf(event.status.id) !== -1) element.addClass('eventWithWarning')

            if (Number(event.status.id) === OFFER_STATUSES.DRAFT_OFFER) {
              element.addClass('event-draft')
            }
          }
        }
      })

      this.miniCalendar = window.$('#mini-calendar').fullCalendar({
        header: {
          left: '',
          center: 'title',
          right: 'prev, next'
        },
        changeMonth: true,
        changeYear: true,
        height: 350,
        events: this.events,
        dayClick: (date, jsEvent, view) => {
          this.openDate(date)
        },
        eventRender: (event, element) => {
          const warningStatuses = [OFFER_STATUSES.DECLINED, OFFER_STATUSES.CANCELLED]
          const eventClass = warningStatuses.indexOf(event.status.id) === -1 ? 'dayWithEvent' : 'dayWithEvent eventWithWarning'
          window.$(`#mini-calendar .fc-body thead td[data-date='${event.start.format('YYYY-MM-DD')}']`).addClass(eventClass)
        }
      })

      window.$('#mini-calendar .fc-prev-button').click(function () {
        window.$('#calendar').fullCalendar('prev')
        window.syncCalendarDates()
      })

      window.$('#mini-calendar .fc-next-button').click(function () {
        window.$('#calendar').fullCalendar('next')
        window.syncCalendarDates()
      })

      window.$('#calendar .fc-prev-button').click(function () {
        window.$('#mini-calendar').fullCalendar('prev')
        window.syncCalendarDates()
      })

      window.$('#calendar .fc-next-button').click(function () {
        window.$('#mini-calendar').fullCalendar('next')
        window.syncCalendarDates()
      })

      window.syncCalendarDates = function () {
        const viewData = window.$('#calendar').fullCalendar('getView')
        let startDate = viewData.intervalStart._d
        window.$('#mini-calendar').fullCalendar('gotoDate', startDate)
      }

      let calenH = document.querySelector('.general-calendar .fc-view').clientHeight
      document.querySelector('.offers-box-over').style.maxHeight = calenH - 50 + 'px'

      window.addEventListener('resize', this.onResize)

      this.putOffersToCalendar(this.calendarOffers)

      this.$nextTick(() => {
        if (this.selectedDate) {
          this.miniCalendar.fullCalendar('changeView', 'month', this.selectedDate)
          this.miniCalendar.fullCalendar('gotoDate', this.selectedDate)
          this.mainCalendar.fullCalendar('changeView', 'agendaDay')
          this.mainCalendar.fullCalendar('gotoDate', this.selectedDate)
        }
      })
    },
    destroyed () {
      window.removeEventListener('resize', this.onResize)
    }
  }
</script>

<style lang="scss">
  .calendar-group-col:last-of-type {
    border-bottom: 1px solid #e7e7e7;
  }
  .fc-day-number {
    transition: none !important;
  }
  #mini-calendar{
    .dayWithEvent:not(.fc-today) {
      background: #d0ab24 !important;
      &.eventWithWarning {
        background: #ed4e4e !important;
      }
      border-radius: 50%;
      span {
        margin: 0 auto !important;
        padding: 0 !important;
        width: 50px !important;
      }
      &:hover {
        span {
          color: #fff !important;
        }
      }
    }
  }

  #calendar {
    .fc-view-container {
      overflow: auto;
    }
    .event-draft {
      background: #b06900 !important;
      border-color: #b06900 !important;
      color: #f8f8f8;
    }
    .eventWithWarning {
      background: #ed4e4e !important;
      border-color: #ed4e4e !important;
      color: #272727;
      &:hover {
        color: #fff;
      }
    }
  }

  .calendar-group-col:last-of-type {
    max-width: 345px;
  }

  .offer_status {
    font-size: 12px;
    color: rgb(86, 86, 86);
  }

  @media only screen and (max-width: 1199px) {
    .calendar-group-col:last-of-type {
      max-width: 100%;
    }
  }
</style>
