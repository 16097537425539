import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import store from './../vuex'
import ConfigHelper from '../helpers/ConfigHelper'
import Registration from '../pages/Registration/Registration.vue'
import RegistrationCompany from '../pages/Registration/RegistrationCompany.vue'
import RegistrationFreelancer from '../pages/Registration/RegistrationFreelancer.vue'
import RegistrationSuccess from '../pages/Registration/RegistrationSuccess.vue'
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword.vue'
import SignIn from '../pages/SignIn/SignIn.vue'
import Profile from '../pages/Profile/Profile.vue'
import Info from '../pages/Profile/Info.vue'
import UpdateCreds from '../pages/Profile/UpdateCreds.vue'
import PaymentHistory from '../pages/Profile/PaymentHistory.vue'
import Reviews from '../pages/Profile/Reviews.vue'
import CompanyList from '../pages/Company/CompanyList.vue'
import CompanyDetails from '../pages/Company/CompanyDetails.vue'
import FreelancerList from '../pages/Freelancer/FreelancerList.vue'
import FreelancerDetails from '../pages/Freelancer/FreelancerDetails.vue'
import JobBoard from '../pages/JobBoard/JobBoard.vue'
import OpenOffers from '../pages/JobBoard/OpenOffers.vue'
import JobMap from '../pages/JobBoard/JobMap.vue'
import OpenOfferDetails from '../pages/JobBoard/OpenOfferDetails'
import OfferCreation from '../pages/Calendar/OfferCreation.vue'
import CalendarWrapper from '../pages/Calendar/CalendarWrapper.vue'
import MyCalendar from '../pages/Calendar/MyCalendar.vue'
import MyJobOffers from '../pages/Calendar/MyJobOffers.vue'
import JobOffersInWork from '../pages/Calendar/JobOffersInWork.vue'
import SingleJobOffer from '../pages/Calendar/SingleJobOffer.vue'
import LandingPage from '../pages/Landing/LandingPage.vue'
import TermsConditions from '../pages/TermsConditions/TermsConditions.vue'
import Dashboard from '../pages/Dashboard/Dashboard'
import Notifications from '../components/Notifications'
import OfferEdit from '../pages/Calendar/OfferEdit'

Vue.use(VueRouter)

const BASE_URL = ConfigHelper.get('apiUrl')

const isAuthorized = async function () {
  axios.get(`${BASE_URL}/is_authorized`).then(res => {
    return res.data.authorized
    // eslint-disable-next-line handle-callback-err
  }).catch(error => {
    console.log(error)
    return false
  })
}

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: SignIn,
    meta: {hideFromAuthenticated: true}
  },
  {
    path: '/registration',
    component: Registration,
    name: 'registration',
    meta: {hideFromAuthenticated: true}
  },
  {
    path: '/registration-company',
    component: RegistrationCompany,
    name: 'registrationCompany',
    meta: {hideFromAuthenticated: true}
  },
  {
    path: '/registration-freelancer',
    component: RegistrationFreelancer,
    name: 'registrationFreelancer',
    meta: {hideFromAuthenticated: true}
  },
  {
    path: '/registrationSuccess',
    component: RegistrationSuccess,
    name: 'registrationSuccess'
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    name: 'forgotPassword',
    meta: {hideFromAuthenticated: true}
  },
  {
    path: '/forgotPassword/:token',
    component: ForgotPassword,
    name: 'newPassword',
    meta: {hideFromAuthenticated: true}
  },
  {
    path: '/confirmation/:id',
    beforeEnter (to, from, next) {
      axios.put(`${ConfigHelper.get('apiUrl')}${to.fullPath}`).then(res => {
        if (res.data.data.app_registration) {
          // redirect to app
          window.Materialize.toast(
            'You have completed the registration successfully! Feel free to sign in to your account', 4000)
          setTimeout(() => {
            window.location.href = 'suitsin://confirmemail'
          }, 4000)
        } else {
          // redirect to web
          router.push('/signIn')
          setTimeout(() => {
            window.Materialize.toast(
              'You have completed the registration successfully! Feel free to sign in to your account', 4000)
          }, 2000)
        }
      }).catch(error => {
        router.push('/signIn')
        setTimeout(() => {
          window.Materialize.toast('An error occurred, email was not confirmed. Please try again', 4000)
        }, 2000)
        console.log(error)
      })
    }
  },
  {
    path: '/offer_payment_link/:offer_id/:payment_id',
    beforeEnter (to, from, next) {
      axios.get(`${ConfigHelper.get('apiUrl')}${to.fullPath}`).then(res => {
        if (res.data.success) {
          window.location.replace(res.data.link)
        } else {
          if (isAuthorized()) {
            router.push('/myCalendar')
          } else {
            router.push('/signIn')
          }
          setTimeout(() => {
            window.Materialize.toast(res.data.message, 4000)
          }, 2000)
        }
      }).catch(error => {
        router.push('/signIn')
        setTimeout(() => {
          window.Materialize.toast('An error occurred. Please try again', 4000)
        }, 2000)
        console.log(error)
      })
    }
  },
  {
    path: '/offer_payment_result/:offer_id/:type',
    beforeEnter (to, from, next) {
      let paymentMessage = 'Your payment was successful. Payment is being processed'
      if (to.params.type !== 'success') {
        paymentMessage = 'The payment was canceled. Please try again later.'
      }

      if (isAuthorized()) {
        router.push('/myCalendar')
      } else {
        router.push('/signIn')
      }

      setTimeout(() => {
        window.Materialize.toast(paymentMessage, 4000)
      }, 2000)
    }
  },
  {
    path: '/email_confirmation/:id',
    beforeEnter (to, from, next) {
      axios.put(`${ConfigHelper.get('apiUrl')}${to.fullPath}`).then(res => {
        router.push('/signIn')
        setTimeout(() => {
          window.Materialize.toast('New email was confirmed successfully', 4000)
        }, 2000)
      }).catch(error => {
        router.push('/signIn')
        setTimeout(() => {
          window.Materialize.toast('An error occured, email was not confirmed. Please try again', 4000)
        }, 2000)
        console.log(error)
      })
    }
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    meta: {requiresAuth: true}
  },
  {
    path: '/notifications',
    component: Notifications,
    name: 'notifications',
    meta: {requiresAuth: true}
  },
  {
    path: '/profile',
    component: Profile,
    meta: {requiresAuth: true},
    children: [
      {path: '', component: Info, name: 'profile'},
      {path: 'updateCreds', component: UpdateCreds, name: 'updateCreds'},
      {path: 'paymentHistory', component: PaymentHistory, name: 'paymentHistory'},
      {path: 'reviews', component: Reviews, name: 'reviews'}
    ]
  },
  {
    path: '/companies',
    component: CompanyList,
    name: 'companyList',
    meta: {requiresAuth: true}
  },
  {
    path: '/companies/:id',
    component: CompanyDetails,
    meta: {requiresAuth: true}
  },
  {
    path: '/companies/:id/offers/:offer_id',
    component: OpenOfferDetails,
    meta: {requiresAuth: true},
    props: {
      backButtonPath: 'profile'
    }
  },
  {
    path: '/freelancers',
    component: FreelancerList,
    name: 'freelancerList',
    meta: {requiresAuth: true}
  },
  {
    path: '/freelancers/:id',
    component: FreelancerDetails,
    name: 'freelancerDetails',
    meta: {requiresAuth: true}
  },
  {
    path: '/jobBoard',
    component: JobBoard,
    meta: {requiresAuth: true},
    children: [
      {
        path: '',
        component: OpenOffers,
        name: 'openOffers'
      },
      {
        path: '/jobBoard/map',
        component: JobMap,
        name: 'openOffersMap'
      },
      {
        path: '/jobBoard/:offer_id',
        component: OpenOfferDetails,
        name: 'openOfferDetails',
        props: {
          backButtonPath: 'profile'
        }
      }
    ]
  },
  {
    path: '/myCalendar',
    component: CalendarWrapper,
    meta: {requiresAuth: true},
    children: [
      {path: '', component: MyCalendar, name: 'myCalendar', props: true},
      {path: 'myJobOffers', component: MyJobOffers, name: 'myJobOffers'},
      {path: 'myJobOffers/:offer_id', component: SingleJobOffer, name: 'singleJobOffer'},
      {path: 'editOffer/:offer_id', component: OfferEdit, name: 'editOffer'},
      {path: 'jobOffersInWork', component: JobOffersInWork, name: 'jobOffersInWork'},
      {
        path: 'offerCreation',
        component: OfferCreation,
        name: 'offerCreation'
      }
    ]
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: TermsConditions
  },
  {
    path: '/privacy-statement',
    name: 'PrivacyStatement',
    component: TermsConditions
  },
  {
    path: '*',
    redirect: '/signIn'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// TODO: this will grow out of control really soon, need to find a better solution
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  let isRequiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let isAuthenticated = store.getters.isAuthenticated
  let isProfileCompleted = store.getters.isProfileCompleted
  let isHideFromAuthenticated = to.matched.some(record => record.meta.hideFromAuthenticated)

  if (isAuthenticated && !isProfileCompleted && to.name !== 'profile') {
    window.Materialize.toast('Please fill out the profile before you start using the application.', 4000)
    next({path: '/profile'})
    return
  }

  if (isRequiresAuth && !isAuthenticated) {
    next({path: '/signIn'})
    return
  }

  if (isHideFromAuthenticated && isAuthenticated) {
    next({path: '/profile'})
    return
  }

  next()
})

export default router
