<template>
  <div class="wrapper-inner">
    <div class="list-container list-container--detail">
      <div class="list-container__head clearfix">
        <router-link :to="{name: 'companyList'}"class="back"><span class="icon-ico-37-back"></span>Back</router-link>
      </div>
      <div class="list-container__body">
        <div class="company-detail">
          <div class="company-detail__icon">
            <div v-if="companyDetails.image_url"
                 class="avatar-image"
                 id="profileImage"
                 :style="{ backgroundImage: 'url(' + getStorageUrl() + companyDetails.image_url + ')' }"> </div>
            <div v-else class="avatar-image"><img src="../../assets/images/profilePlaceholder.png" alt=""></div>
          </div>
          <div class="company-detail__info">
            <div class="list-item__name">
              <span>{{companyDetails.name}}</span>
              <template v-if="companyDetails.location">{{companyDetails.location.formatted_address}}</template>
            </div>
            <div class="company-info__detail">
              <ul>
                <li><span class="icon-ico-58-active-offers"></span>{{companyDetails.activeOffers}} Active offers</li>
                <li><span class="icon-ico-59-job"></span>{{companyDetails.newOffers}} New offers</li>
                <li><span class="icon-ico-60-complete"></span>{{companyDetails.doneOffers}} job offers successfully completed</li>
                <li v-if="companyDetails.rating">
                  <div class="list-item-raiting"><span class="icon-ico-53-rate"></span>{{companyDetails.rating}}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="list-container-body-head list-container-body-head--cus">
          <ul class="tabs tab-navigate ">
            <li class="tab">
              <a href="#tab1" class="active">
                <span v-if="companyOffers.length === 0">No jobs</span>
                <span v-else>All Jobs</span>
              </a>
            </li>
            <li class="tab">
              <a href="#tab2">
                <span>Reviews <span class="count">{{Object.keys(companyReviews).length > 99 ? '99+' : Object.keys(companyReviews).length}}</span></span>
              </a>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div class="tab-box" id="tab1">
            <div v-for="offer in companyOffers" class="offer-item">
              <div class="offer-item__name">{{offer.name}}</div>
              <div class="offer-item__detail">
                <ul>
                  <li><span class="icon-ico-40-date"></span>{{formatDate(offer.date)}} - {{formatTime(offer.time)}}</li>
                  <li><span class="icon-ico-42-money"></span>$ {{offer.price}}</li>
                </ul>
              </div>
              <div class="offer-item__info">
                <p>{{offer.description}}</p>
                <ul>
                  <li><span>Type:</span>{{offer.offerType.name}}</li>
                  <li><span class="icon-ico-43-category"></span>{{offer.category.name}}</li>
                  <li v-if="offer.location"><span class="icon-ico-44-location"></span>{{offer.location.formatted_address}}</li>
                  <li v-if="offer.offerProfile"><span>Freelancer type</span>{{offer.offerProfile.name}}</li>
                </ul>
                <div class="offer-item__button">
                  <router-link :to="'offers/' + offer.id" append>
                    <app-button cssClass="btn--default" title="View" :slideOnHover="false"/>
                  </router-link>
                </div>
              </div>
            </div>
            <pagination class="notificationPaginator" :current-page="currentPage"
                        :total-items="companyOffersTotal"
                        :itemsPerPage="perPage"
                        @page-changed="pageChanged">
            </pagination>
          </div>
          <div class="tab-box" id="tab2">
            <div class="review-box">
              <div class="reviews-item" v-for="review in companyReviews">
                <div class="reviews-author">
                  <div class="reviews-author__img">
                    <img :src="getStorageUrl() + review.freelancer.image_url" alt="">
                  </div>
                  <router-link :to="'/freelancers/' + review.freelancer.id" :key="review.id" class="reviews-author__name">
                    {{review.freelancer.name + ' ' + review.freelancer.lastname}}
                  </router-link>
                  <div class="reviews-date">
                    {{review.created_at}}
                  </div>
                </div>
                <div class="reviews-body">
                  {{review.reason && review.reason}}
                  <div class="clearfix">
                    <div class="review-rating">
                      <star-rating :rating="parseFloat(review.rating)" :read-only="true" :star-size="10" :show-rating="false" :max-rating="5"></star-rating>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  import Button from '../../components/Button.vue'
  import StarRating from 'vue-star-rating'
  import ImageHelper from '../../helpers/ImageHelper'
  import DateHelper from '../../helpers/DateHelper'
  import Pagination from '../../components/Pagination'
  export default {
    data: () => ({
      currentPage: 1,
      perPage: 50
    }),
    computed: {
      ...mapGetters(['companyDetails', 'companyOffers', 'companyOffersTotal', 'companyReviews'])
    },
    mixins: [ImageHelper, DateHelper],
    methods: {
      ...mapActions(['fetchCompanyDetails', 'fetchCompanyOffers', 'fetchCompanyReviews', 'companyDetailsCancel']),
      pageChanged (pageNum) {
        this.currentPage = pageNum
        this.fetchCompanyOffers({
          id: this.$route.params.id,
          page: this.currentPage
        })
      }
    },
    mounted () {
      window.$('ul.tabs').tabs()
      this.fetchCompanyReviews(this.$route.params.id)
      this.fetchCompanyDetails(this.$route.params.id)
      this.fetchCompanyOffers({
        id: this.$route.params.id
      })
    },
    destroyed () {
      this.companyDetailsCancel()
    },
    components: {
      'app-button': Button,
      'pagination': Pagination,
      StarRating
    }
  }
</script>
<style>
.tabs .tab:not(:first-child) {
  margin-left: 10px;
}
</style>
