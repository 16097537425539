<template>
  <div class="calendar-body job-offers--one my-calendar-task">
    <div class="list-container">
      <div class="list-container__body">
        <div class="event-container">
          <div class="row">
            <div class="col l9 s12">
              <div class="event-box" v-if="offer.id">
                <div class="list-container-body-head">
                  {{ offer.name }}
                  <span class="status-offer"
                      v-if="offer.public"
                  >Public</span>
                  <span v-else class="status-offer">
                    <span>Private</span>
                    <img src="/images/private.svg" alt="Private (lock)">
                  </span>
                </div>
                <div class="event-top">
                  <assigned-freelancer
                      :freelancer="offer.freelancer"
                      subtext=""
                  ></assigned-freelancer>
                  <div v-if="isCompany(profileInfo)" class="event-top-button">
                    <span v-if="offer.payment_in_processing === 1">Your payment is being processed.</span>

                    <router-link v-if="canEditOffer(offer)" class="event-button"
                        :to="{name: 'editOffer', params: { offer_id: offer.id }}">
                      Edit
                    </router-link>

                    <a @click="openRatingModal" v-if="canLeaveFeedback(offer)" class="modal-trigger event-button"
                        data-target="modal-add-rating">
                      Leave feedback
                    </a>
                    <a @click="doneOfferConfirm(offer)" v-if="canBeDone(offer)"
                        class="modal-trigger event-button event-button--complite">
                      <span class="icon-ico-57-check"></span>
                      Approve work
                    </a>
                    <a @click="sendOfferToSupportConfirm(offer)" v-if="canRequestToFixReview(offer)"
                        class="modal-trigger event-button">
                      Send to Support
                    </a>
                    <a @click="sendOfferToFixConfirm(offer)" v-if="canRequestToFixReview(offer)"
                        class="modal-trigger event-button">
                      Request to fix
                    </a>
                    <a :href="offer.payment_approve_url" v-if="canBePayed(offer)"
                        class="event-button event-button--complite">
                      <span class="icon-ico-57-check"></span>
                      Pay
                    </a>
                    <a v-if="canBeCancelled(offer)" @click="cancelOfferConfirm(offer)"
                        class="modal-trigger event-button">Cancel Offer</a>
                  </div>
                  <div v-if="isInvitedFreelancer(profileInfo)" class="event-top-button">
                    <a v-if="isActive(offer) && !alreadyBidded" @click="openAcceptOfferModal" class="modal-trigger event-button"
                        data-target="modal-accept-offer">
                      <span class="icon-ico-57-check"></span>
                      Accept offer
                    </a>
                    <span v-if="isActive(offer) && alreadyBidded" @click="openSentOfferModal" class="modal-trigger event-button"
                        data-target="modal-sent-offer">
                      Made proposition
                    </span>
                    <a v-if="isActive(offer)" @click="declineOffer(offer)" class="modal-trigger event-button">Decline</a>
                    <a v-if="isActive(offer)" @click="declineAndRecommend(offer)" class="modal-trigger event-button"
                        data-target="modal-choose-friend">
                      Recommend a friend
                    </a>
                  </div>
                  <div v-if="isAssignedFreelancer(profileInfo)" class="event-top-button">
                    <a @click="startOfferConfirm(offer)" v-if="canBeStarted(offer)" class="event-button">
                      Start the Offer
                    </a>
                    <a @click="sendOfferToReviewConfirm(offer)" v-if="canBeSentToReview(offer)" class="event-button">
                      Send to review
                    </a>
                    <a @click="showResolvePendingModal(offer)" v-if="visiblePendingDialog(offer)"
                        class="modal-trigger event-button" data-target="pending-dialog">
                      Resolve pending status
                    </a>
                    <a @click="openRatingModal" v-if="canLeaveFeedback(offer)" class="modal-trigger event-button"
                        data-target="modal-add-rating">
                      Leave feedback
                    </a>
                  </div>
                  <div class="event-list">
                    <ul>
                      <li><span class="icon-ico-40-date"></span>{{ formatDate(offer.date) }}</li>
                      <li><span class="icon-ico-41-time"></span>{{ formatTime(offer.time) }}</li>
                      <li><span class="icon-ico-42-money"></span>$ {{ offer.price }}</li>
                      <li v-if="isCancelled(offer)">
                        <div class="offers-box-item__status declined">{{ getStatusName(offer) }}</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="event-body">
                  <div class="event-list">
                    <ul>
                      <li><span>Type</span>{{ offer.offerType.name }}</li>
                      <li><span class="icon-ico-43-category"></span>{{ offer.category.name }}</li>
                      <li v-if="offer.location"><span class="icon-ico-44-location"></span>{{ offer.location.formatted_address }}</li>
                      <li v-if="offer.conference_link">
                        <span>Conference link</span>
                        <template v-for="(part, index) in parseConferenceLink(offer.conference_link)">
                          <a v-if="part.isUrl" :href="part.text" target="_blank" style="margin-right: 10px; color: #575757">{{ part.text }}</a>
                          <span v-else style="color: #575757">{{ part.text }}</span>
                          <span v-if="index < parseConferenceLink(offer.conference_link).length - 1" style="color: #9d9d9d">|</span>
                        </template>
                      </li>
                      <li v-if="offer.offerProfile"><span>Freelancer type</span>{{offer.offerProfile.name}}</li>
                    </ul>
                  </div>
                  <div v-if="isCompany(profileInfo) && !offer.freelancer" class="bids-list">
                    <div>
                      <b v-if="offer.biddedFreelancers && !!offer.biddedFreelancers.length">Freelancers Bids</b>
                      <b v-else>No freelancers have bid this job offer</b>
                    </div>
                    <div class="event-comment__body">
                      <div class="tab-content">
                        <div v-for="freelancer in offer.biddedFreelancers" class="recomended-item">
                          <div class="recomended-item__img" @click="showFreelancerInNewTab(freelancer)">
                            <div v-if="freelancer.image_url"
                                class="friend-icon"
                                id="freelancer-image"
                                :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancer.image_url + ')' }"></div>
                            <img v-if="!freelancer.image_url" src="../../assets/images/profilePlaceholder.png" alt=""/>
                          </div>
                          <div class="recomended-item__info" @click="showFreelancerInNewTab(freelancer)">
                            <span>{{ freelancer.name }} {{ freelancer.lastname }}</span>
                            <span v-if="freelancer.bid_price">Freelancer's bid price: {{ freelancer.bid_price }}</span>
                            <span v-else>Bid price: {{ offer.price }}</span>
                            <span v-if="freelancer.bid_comment">Freelancer's comment: {{ freelancer.bid_comment }}</span>
                          </div>
                          <div class="recomended-item__button">
                            <a @click="acceptBidConfirm(freelancer)" class="event-button" title="Accept dialog">
                              <span class="icon-ico-57-check"></span>Аccept
                            </a>
                            <a @click="cancelBidConfirm(freelancer)" class="recomend-remove" title="Deny bid">
                              <span class="icon-ico-50-delete"></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="event-body-txt">
                    <p>
                      {{ offer.description }}
                    </p>
                  </div>
                  <div v-if="isCompany(profileInfo) && offer && offer.invitedFreelancer" class="offer-from">
                    This offer to
                    <router-link :to="'/freelancers/' + offer.invitedFreelancer.id">
                      {{ offer.invitedFreelancer.name + ' ' + offer.invitedFreelancer.lastname }}
                    </router-link>
                  </div>
                  <div v-else-if="offer && offer.company.id" class="offer-from">
                    This offer from
                    <router-link :to="'/companies/' + offer.company.id">
                      {{ offer.company.name }}
                    </router-link>
                  </div>
                  <div v-if="!!offer.offerAttachments.length" class="attachment">
                    <div class="attachment-box">
                      <div class="attachment-head">Attachments</div>
                      <div class="attachment-list">
                        <div v-for="file in offer.offerAttachments" class="attachment-item">
                          <img :src="getIcon(file.url)" alt="">
                          <span class="attachment-name">{{ file.name }}</span>
                          <a :href="getStorageUrl() + file.url" target="_blank" class="dowload-file">
                            <span class="icon-ico-47-download"></span>Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="isDeclined(offer) && isCompany(profileInfo) && !offer.invitedFreelancer" class="event-comment">
                <div class="event-comment__head declined-events">
                  <b>Your invitation was declined</b>
                  <a @click="declineAndRecommend(offer)" class="modal-trigger event-button" data-target="modal-choose-friend">Select new Freelancer</a>
                  <a @click="sendToJobBoardConfirm(offer)" class="modal-trigger event-button">Send To Job Board</a>
                  <a @click="cancelOfferConfirm(offer)" class="modal-trigger event-button">Cancel Offer</a>
                </div>
                <div class="event-comment__body">
                  <div class="tab-content">
                    <div>Here are people that the original freelancer recommends</div>
                    <div class="recomended-list">
                      <div v-for="freelancer in offer.recommendedFreelancers" class="recomended-item">
                        <div class="recomended-item__img" @click="showFreelancerInNewTab(freelancer)">
                          <div v-if="freelancer.image_url"
                              class="friend-icon"
                              id="freelancer-image"
                              :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancer.image_url + ')' }"></div>
                          <img v-if="!freelancer.image_url" src="../../assets/images/profilePlaceholder.png" alt=""/>
                        </div>
                        <div class="recomended-item__info" @click="showFreelancerInNewTab(freelancer)">
                          <span>{{ freelancer.name }} {{ freelancer.lastname }}</span>
                        </div>
                        <div class="recomended-item__button">
                          <a @click="sendInvitationToFreelancer(freelancer)" class="event-button">
                            <span class="icon-ico-57-check"></span>
                            Аccept
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col l3 s12">
              <status-changer v-if="offer.id" :offer="offer" :offerStatus="offer.status" :offerId="offer.id"/>
            </div>
          </div>

          <div v-if="visibleComments(offer)" class="event-comment">
            <div class="event-comment__head">
              <div class="col s12">
                <ul class="tabs">
                  <li class="tab"><a>Comments</a></li>
                </ul>
              </div>
            </div>
            <div class="event-comment__body">
              <div class="tab-content">
                <div v-if="visibleAddCommentForm(offer)" class="add-comment">
                  <div v-if="profileInfo && profileInfo.image_url"
                       class="avatar comment-avatar"
                       :style="{ backgroundImage: 'url(' + getStorageUrl() + profileInfo.image_url + ')' }"> </div>
                  <div v-else class="add-comment-avatar"><img src="../../assets/images/profilePlaceholder.png" alt=""></div>
                  <div class="add-comment-form">
                    <form>
                      <div
                        :data-error="errors.first('Comment.Comment Body')"
                        :class="[{error: this.errors.has('Comment.Comment Body')}, 'input-field', 'required-field']">
                          <textarea
                            placeholder="Leave your comment here..."
                            v-model="commentBody"
                            class="materialize-textarea"
                            v-validate="'validate_no_email|validate_no_phone'"
                            name="Comment Body"
                            data-vv-scope="Comment"
                            data-vv-validate-on="submit"></textarea>
                      </div>
                      <div class="input-filed" v-if="commentAddFile">
                        <div class="label-field">
                          Attachments:
                        </div>
                        <div class="input-field">
                          <file-dropzone :onFilesChanged="onFilesChanged" ref="commentDropzone"/>
                        </div>
                      </div>
                      <div class="comment-events">
                        <a @click="commentAddFile = true" class="event-button">Add Attachment</a>
                        <a @click="postComment" class="event-button event-button--complite"><span class="icon-ico-57-check"></span>Add Comment</a>
                      </div>
                    </form>
                  </div>
                </div>
                <div v-for="comment in offer.comments" class="comment-item">
                  <div v-if="!comment.role_id">
                    <div class="comment-item__img">
                      <div class="add-comment-avatar"><img src="../../assets/images/profilePlaceholder.png" alt=""></div>
                    </div>
                    <div class="comment-item__info">
                      <div class="comment-item__head"><span>SuitsIn Support</span>{{calendarDateTime(comment.updated_at)}}</div>
                      <div class="comment-item__txt">{{comment.body}}</div>
                    </div>
                  </div>
                  <div v-if="isCompany(comment)">
                    <div v-if="isCompany(comment)" class="comment-item__img">
                      <div v-if="offer.company.image_url"
                           class="avatar comment-avatar"
                           :style="{ backgroundImage: 'url(' + getStorageUrl() + offer.company.image_url + ')' }"> </div>
                      <div v-else class="add-comment-avatar"><img src="../../assets/images/profilePlaceholder.png" alt=""></div>
                    </div>
                    <div class="comment-item__info">
                      <div class="comment-item__head"><span>{{offer.company.name}}</span>{{calendarDateTime(comment.updated_at)}}</div>
                      <div class="comment-item__txt">{{comment.body}}</div>
                    </div>
                  </div>
                  <div v-if="isFreelancerProf(comment)">
                    <div v-if="isFreelancerProf(comment)" class="comment-item__img">
                      <div v-if="offer.freelancer.image_url"
                           class="avatar comment-avatar"
                           :style="{ backgroundImage: 'url(' + getStorageUrl() + offer.freelancer.image_url + ')' }"> </div>
                      <div v-else class="add-comment-avatar"><img src="../../assets/images/profilePlaceholder.png" alt=""></div>
                    </div>
                    <div class="comment-item__info">
                      <div class="comment-item__head"><span>{{offer.freelancer.name}}</span>{{calendarDateTime(comment.updated_at)}}</div>
                      <div class="comment-item__txt">{{comment.body}}</div>
                    </div>
                  </div>
                  <div v-if="!!comment.comment_attachments.length" class="attachment">
                    <div class="attachment-box">
                      <div class="attachment-head">Comment Attachments</div>
                      <div class="attachment-list">
                        <div v-for="file in comment.comment_attachments" class="attachment-item">
                          <img :src="getIcon(file.url)" alt="">
                          <span class="attachment-name">{{file.name}}</span>
                          <a :href="getStorageUrl() + file.url" target="_blank" class="dowload-file">
                            <span class="icon-ico-47-download"></span>Download
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <rating :ratedUser="isCompany(profileInfo) ? offer.freelancer : offer.company" :offer="offer"/>

    <div id="modal-sent-offer" class="modal fade">
      <div class="modal-head">Accept offer or propose another price</div>
      <div class="modal-content">
        <p>You can make proposition only one time. Please wait for answer from company</p>
        <br/>
        <div class="modal-content__button-wrapper">
          <app-button title="Close" cssClass="modal-close"/>
        </div>
      </div>
    </div>

    <div id="pending-dialog" class="modal">
      <div class="modal-head">Resolve pending status</div>
      <div class="modal-content">
        <p>The company changed status of this offer to “Pending”.</p>
        <p>This means there was an issue with this job.</p>
        <p>If you accept that something needs to be fixed the status will be changed to “In Progress” after you press the “Accept” button.</p>
        <p>If you decline the company’s statement, SuitsIn will conduct an investigation.</p>
        <br/>
        <div class="modal-content__button-wrapper">
          <app-button title="Accept" :onClick="resolvePendingAsAccept"/>
          <app-button title="Decline" :onClick="resolvePendingAsDecline"/>
          <app-button title="Close" cssClass="modal-close"/>
        </div>
      </div>
    </div>

    <div id="modal-accept-offer" class="modal">
      <div class="modal-head">Accept offer or propose another price</div>
      <div class="modal-content">
        <form action="#" @submit.prevent="sendBidOrAssign">
          <div class="input-field left-align">
            <div class="label-field">&nbsp;</div>
            <div class="label-area">
              <input id="checkChange" type="checkbox" class="filled-in" value="true" v-model="bidData.changePrice">
              <label for="checkChange">Propose another price</label>
            </div>
          </div>
          <div v-if="bidData.changePrice"
              :data-error="errors.first('bidData.price')"
              :class="[{error: this.errors.has('bidData.price')}, 'input-field', 'required-field']"
          >
            <div class="label-field">Price, $</div>
            <div class="label-area">
              <input type="number"
                  step="1"
                  v-validate="`decimal:2|min_value:${bidData.minPrice}`"
                  data-vv-scope="bidData"
                  name="price"
                  v-model.lazy="bidData.price">
            </div>
          </div>
          <div v-if="bidData.changePrice" class="input-field">
            <div class="label-field">Comment</div>
            <div class="label-area">
              <textarea class="materialize-textarea" aria-invalid="false" rows="5" v-model.lazy="bidData.comment"></textarea>
            </div>
          </div>
        </form>
        <div class="modal-content__button-wrapper">
          <app-button :title="bidData.changePrice ? 'Send Proposition': 'Accept Offer'" :onClick="sendBidOrAssign" />
          <app-button title="Cancel" cssClass="modal-close"/>
        </div>
      </div>
    </div>

    <!--TODO move this into a separate component-->
    <div id="modal-choose-friend" class="modal">
      <div class="modal-head">Choose a freelancer</div>
      <div class="modal-content">
        <div class="thank-friend">
          <div class="search-friend">
            <div class="input-field search-field">
              <input type="search" placeholder="Find a freelancer" @keyup="filterFreelancersByName">
            </div>
          </div>
          <div class="friend-list">
            <div class="friend" v-for="freelancer in freelancerList" v-if="freelancer.id !== profileInfo.id">
              <div class="friend-col" @click="showFreelancerInNewTab(freelancer)">
                <div v-if="freelancer.image_url"
                    class="friend-icon"
                    id="freelancer-image"
                    :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancer.image_url + ')' }"></div>
                <div v-else class="friend-icon">
                  <img src="../../assets/images/profilePlaceholder.png" alt="">
                </div>
                <div class="friend-name">{{ freelancer.name }} {{ freelancer.lastname }}</div>
              </div>
              <app-button title="Send" :onClick="() => selectFreelancer(freelancer)">
                Send
              </app-button>
            </div>
          </div>
        </div>
        <div>
          <app-button title="Cancel" cssClass="modal-close"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import Button from '../../components/Button.vue'
  import StatusChanger from '../../components/StatusChanger.vue'
  import Rating from '../../components/Rating.vue'
  import FileDropzone from '../../components/FileDropzone.vue'
  import ImageHelper from '../../helpers/ImageHelper'
  import RolesHelper from '../../helpers/RolesHelper'
  import StatusHelper from '../../helpers/StatusHelper'
  import DateHelper from '../../helpers/DateHelper'
  import LinkHelper from '@/helpers/LinkHelper'
  import AssignedFreelancer from './AssignedFreelancer'
  import { OFFER_STATUSES } from '@/helpers/Enums'
  import { swalOptionsForQuestion, swalOptionsForQuestionWithInput } from '@/misc/swallOptions'
  import * as types from '../../vuex/profile/mutationTypes'



const freshBidData = {
    changePrice: false,
    price: '0',
    minPrice: '0',
    comment: ''
  }

  let searchTimer = null

  export default {
    data () {
      return {
        commentAddFile: false,
        commentBody: '',
        attachments: null,
        bidData: freshBidData,
        roleForSearch: null
      }
    },
    computed: {
      ...mapGetters({
        calendarOffers: 'calendarOffers',
        offer: 'openOfferDetails',
        profileInfo: 'profileInfo',
        freelancerList: 'freelancerList',
        lastNotification: 'lastNotification'
      }),
      isProcessing () {
        return this.$store.state.jobOffer.isProcessing
      },
      alreadyBidded () {
        if (this.offer && this.offer.biddedFreelancers) {
          return !!this.offer.biddedFreelancers.find(freelancer => freelancer.id === this.profileInfo.id)
        }
        return false
      }
    },
    mixins: [ImageHelper, RolesHelper, StatusHelper, DateHelper, LinkHelper],
    components: {
      'app-button': Button,
      'status-changer': StatusChanger,
      'rating': Rating,
      'file-dropzone': FileDropzone,
      'assigned-freelancer': AssignedFreelancer
    },
    watch: {
      offer (offerDetails) {
        if (offerDetails) {
          this.bidData.price = offerDetails.price
          this.bidData.minPrice = parseInt(parseInt(offerDetails.price) * 0.5)
        }
      },
      'bidData.changePrice': function (changePrice) {
        if (!changePrice) this.bidData.price = this.offer.price
      },
      profileInfo (profile) {
        this.fetchSingleOffer({profile, offer_id: this.$route.params.offer_id})
      },
      lastNotification: function (notification) {
        if (parseInt(this.$route.params.offer_id) === notification.offer_id) {
          this.fetchSingleOffer({profile: this.profileInfo, offer_id: this.$route.params.offer_id})
          window.Materialize.toast(notification.data.body, 5000)
        }
      },
      $route (route) {
        if (route.params.offer_id !== this.offer.id) {
          this.fetchSingleOffer({profile: this.profileInfo, offer_id: this.$route.params.offer_id})
        }
      }
    },
    methods: {
      ...mapActions(['fetchOpenOfferDetails', 'fetchSingleOffer', 'cancelOfferBid', 'acceptOfferBid', 'assignOffer', 'placeOfferBid', 'cancelInvite', 'fetchFreelancerList',
        'recommendFreelancer', 'sendToFreelancer', 'sendToJobBoard', 'cancelOffer', 'doneOffer', 'changeStatus', 'addOfferComment', 'removeUnreadNotifications']),
      showFreelancerInNewTab (freelancer) {
        window.open('/freelancers/' + freelancer.id)
      },
      acceptBid (bidId) {
        this.acceptOfferBid({offer_id: this.offer.id, bid_id: bidId})
      },
      cancelBid (freelancer) {
        this.cancelOfferBid({offer_id: this.offer.id, freelancer_id: freelancer.id})
      },
      shouldShowToFreelancer (profile) {
        return !this.offer.freelancer || this.offer.freelancer.id !== profile.id
      },
      isInvitedFreelancer (profile) {
        return this.isFreelancerProf(profile) && this.offer.invitedFreelancer && this.offer.invitedFreelancer.id === profile.id
      },
      isAssignedFreelancer (profile) {
        return this.isFreelancerProf(profile) && this.offer.freelancer && this.offer.freelancer.id === profile.id
      },
      openSentOfferModal () {
      },
      openAcceptOfferModal () {
      },
      sendBidOrAssign () {
        if (this.bidData.changePrice) {
          this.$validator.validateAll('bidData').then(result => {
            if (!result) return
            this.placeOfferBid({offer_id: this.offer.id, freelancer_id: this.profileInfo.id, price: this.bidData.price, comment: this.bidData.comment})
            this.bidData = freshBidData
            window.$('.modal').modal('close')
          })
        } else {
          this.assignOffer({offer_id: this.offer.id, freelancer_id: this.profileInfo.id})
          window.$('.modal').modal('close')
        }
      },
      declineOffer (offer) {
        this.cancelInvite(offer.id)
      },
      declineAndRecommend (offer) {
        this.roleForSearch = offer.offerProfile.name === 'Lawyer' ? 2 : 3
        this.fetchFreelancerList({role_id: this.roleForSearch})
      },
      filterFreelancersByName (e) {
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
          this.fetchFreelancerList({search: e.target.value, role_id: this.roleForSearch})
        }, 1000)
      },
      selectFreelancer (freelancer) {
        window.$('.modal').modal('close')
        if (this.isFreelancerProf(this.profileInfo)) {
          this.cancelInvite(this.offer.id)
          this.recommendFreelancer({offer: this.offer.id, freelancer: freelancer.id})
        } else {
          this.sendToFreelancer({offer: this.offer.id, freelancer: freelancer.id, successMessage: 'The invitation was sent'})
        }
      },
      sendInvitationToFreelancer (freelancer) {
        this.sendToFreelancer({offer: this.offer.id, freelancer: freelancer.id, successMessage: 'The invitation was sent'})
      },
      acceptBidConfirm (biddedFreelancer) {
        const additionalText = biddedFreelancer.bid_price
        ? `<br/><br/>Attention!<br/>You will approve proposed price <span class="red-text">$${biddedFreelancer.bid_price}</span> from freelancer.`
        : `<br/><br/>You will approve bid with price: <span class="red-text">$${this.offer.price}</span>`
        this.$swal({
          title: 'Are you sure? You will assign freelancer to this offer.',
          html: 'This action cannot be undone' + additionalText,
          ...swalOptionsForQuestion
        }).then(() => {
          this.acceptBid(biddedFreelancer.bid_id)
        })
      },
      cancelBidConfirm (freelancer) {
        this.$swal({
          title: 'Are you sure?',
          html: 'This action cannot be undone',
          ...swalOptionsForQuestion
        }).then(() => {
          if (this.offer.public) {
            this.cancelBid(freelancer)
          } else {
            this.cancelInvite(this.offer.id)
          }
        })
      },
      sendToJobBoardConfirm (offer) {
        this.$swal({
          title: 'Are you sure?',
          text: 'This offer will be made public',
          ...swalOptionsForQuestion
        }).then(() => {
          this.sendToJobBoard(offer)
        })
      },
      cancelOfferConfirm (offer) {
        this.$swal({
          title: 'Are you sure?',
          text: 'You will cancel this offer and this action cannot be undone!',
          ...swalOptionsForQuestionWithInput,
          inputPlaceholder: 'Please, describe why do you want to cancel this offer.',
          inputValidator: (value) => {
            return new Promise((resolve, reject) => {
              if (value.length < 15) {
                reject('You need to put at least 15 symbols!')
              } else {
                resolve()
              }
            })
          }
        }).then((comment) => {
          this.cancelOffer({ offer, comment })
        })
      },
      startOfferConfirm (offer) {
        this.$swal({
          title: 'Are you sure?',
          html: 'This offer will be marked as "In progress". <br/>This action cannot be undone',
          ...swalOptionsForQuestion
        }).then(() => {
          this.changeStatus({offer: offer.id, status: OFFER_STATUSES.OFFER_IN_PROGRESS})
        })
      },
      sendOfferToReviewConfirm (offer) {
        this.$swal({
          title: 'Are you sure?',
          html: 'This offer will be marked as "In review". <br/>This action cannot be undone',
          ...swalOptionsForQuestion
        }).then(() => {
          this.changeStatus({offer: offer.id, status: OFFER_STATUSES.OFFER_IN_REVIEW})
        })
      },
      sendOfferToFixConfirm (offer) {
        this.$swal({
          title: 'Are you sure?',
          html: 'This offer will be marked as "Need to be fixed.<br/>Freelancer will be notified',
          ...swalOptionsForQuestion
        }).then(() => {
          this.changeStatus({offer: offer.id, status: OFFER_STATUSES.PENDING_ALERT})
        })
      },
      sendOfferToSupportConfirm (offer) {
        this.$swal({
          title: 'Are you sure?',
          html: 'After this, SuitsIn\'s support will judge.',
          ...swalOptionsForQuestion
        }).then(() => {
          this.changeStatus({offer: offer.id, status: OFFER_STATUSES.SUPPORT_REVIEW})
        })
      },
      showResolvePendingModal () {
      },
      resolvePendingAsAccept () {
        this.$swal({
          title: 'Are you sure?',
          html: 'After this you need to fix all issues.',
          ...swalOptionsForQuestion
        }).then(() => {
          window.$('.modal').modal('close')
          this.changeStatus({offer: this.offer.id, status: OFFER_STATUSES.OFFER_IN_PROGRESS})
        })
      },
      resolvePendingAsDecline () {
        this.$swal({
          title: 'Are you sure?',
          html: 'After this, SuitsIn\'s support will judge.',
          ...swalOptionsForQuestion
        }).then(() => {
          window.$('.modal').modal('close')
          this.changeStatus({offer: this.offer.id, status: OFFER_STATUSES.SUPPORT_REVIEW})
        })
      },
      doneOfferConfirm (offer) {
        this.$swal({
          title: 'Are you sure? Freelancer will receive payment.',
          text: 'This action cannot be undone',
          ...swalOptionsForQuestion
        }).then(() => {
          this.doneOffer(offer)
        })
      },
      openRatingModal () {
      },
      onFilesChanged (files) {
        this.attachments = files.length > 0 ? this.attachments = files : this.attachments = null
      },
      postComment () {
        this.$validator.validateAll('Comment')
          .then(async (result) => {
            if (!result) return

            this.addOfferComment({
              body: this.commentBody,
              offer_id: this.offer.id,
              role_id: this.profileInfo.role.id,
              user_id: this.profileInfo.id,
              attachments: this.attachments,
            })

            this.commentBody = ''
            this.commentAddFile = false
          })
      }
    },
    mounted () {
      const offerId = parseInt(this.$route.params.offer_id)
      this.$store.commit(types.NOTIFICATIONS_ADD_IGNORE_TO_BELL, offerId)
      this.$store.commit(types.NOTIFICATIONS_REMOVE_FROM_UNREAD, offerId)

      if (this.profileInfo.id) {
        this.fetchSingleOffer({profile: this.profileInfo, offer_id: offerId})
      }

      window.$('.modal').modal({
        ready () {
          window.$('.modal-overlay').detach().appendTo('.wrapper')
        }
      })
    },
    destroyed () {
      this.$store.commit(types.NOTIFICATIONS_REMOVE_IGNORE_TO_BELL)
    }
  }
</script>

<style lang="scss">
  .job-offers--one .list-container {
    padding-top: 30px;
  }
  .recomended-item, .event-button{
    cursor: pointer;
  }

  .bids-list {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;

    .tab-content {
      padding-right: 0;
      padding-left: 0;
    }

    .recomended-item {
      width: auto;
    }
  }



  .offers-box-item__status.declined {
    color: #ed4e4e;
    border-color: #ed4e4e;
  }

  #modal-accept-offer {
    textarea {
      min-height: 140px !important;
    }
   }

  #modal-choose-friend {
    z-index: 1007 !important;

    .friend-list {
      max-height: 300px;
      overflow-y: scroll;

      .friend-col {
        cursor: pointer;
      }
    }

    #freelancer-image {
      background-repeat: no-repeat;
      background-position: 50%;
      border-radius: 50%;
      width: 49px;
      height: 49px;
      background-size: cover;
    }
  }

  .declined-events {
    padding-bottom: 10px;
  }

  .comment-events {
    margin-top: 10px;
    a {
      overflow: hidden;
      margin-right: 10px;
    }
  }

  .avatar {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    background-size: cover;
  }

  .status-offer {
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  .status-offer img {
    width: 25px;
    height: 25px;
  }

  .comment-avatar {
    width: 50px;
    height: 50px;
  }

  .assigned-freelancer-icon {
    width: 36px;
    height: 36px;
  }

  .attachment-item {
    &:hover {
      background:#fff;
      border-color:#e6e6e6;
      span {
        transform:translateY(-10px);
      }
      .dowload-file {
        visibility: visible;
        opacity: 1;
      }
    }

    img {
      height: 48px;
    }

    .attachment-name {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  .comment-item {
    .attachment {
      padding: 0;
    }
  }


</style>
