<template>
  <div class="clearfix">
    <div class="dashboardWrapper">
      <div class="mobileNav">
        <div class="tabFiler">
          <div :class="{tabFiler_item: true, 'active': tab === 'schedule'}" @click="setTab('schedule')">Schedule</div>
          <div :class="{tabFiler_item: true, 'active': tab === 'notifications'}"
              @click="setTab('notifications')">Notifications
          </div>
        </div>
        <app-select name="freelancer-type" v-model="notificationType">
          <option value="ALL">All</option>
          <option value="1">Payments</option>
          <option value="2">Job Details</option>
        </app-select>
      </div>
      <div class="dashboardWrapper__inner-container">
        <div :class="{dashboardTab: true, 'dashboardTab--active': tab === 'schedule'}">
          <div class="scheduleWrapper">
            <div class="what-next">
              <div class="what-next__inner-wrapper">
                <img class="what-next__image-top" src="../../assets/images/dashboard/what-next-top.svg" alt="">
                <img class="what-next__image-right" src="../../assets/images/dashboard/what-next-right.svg" alt="">
                <div class="what-next__content">
                  <div class="what-next__title">WHAT NEXT?</div>
                  <div class="what-next__text">
                    <template v-if="this.isFreelancer">Search for a job then do the job and get paid.</template>
                    <template v-else>
                      Create a new offer here by pressing the button below or the <span class="plus">+</span> in the upper right corner.
                    </template>
                  </div>
                  <div class="new-btn new-btn-primary new-btn-wide" @click="redirectToOfferCreation">
                    {{ this.isFreelancer ? 'Go to job board' : 'Create new offer' }}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="notificationsTop">
                <div class="columnTitle">Schedule</div>
              </div>
              <div class="calendar mini-calendar">
                <div class="datepicker-date">
                  <div id="mini-calendar-dashboard"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="{dashboardTab: true, 'dashboardTab--active': tab === 'notifications'}">
          <div class="notificationWrapper">
            <div>
              <div class="notificationsTop">
                <div class="columnTitle">Notifications</div>
                <div class="tabFiler">
                  <div v-for="notificationTypeOption in notificationTypes"
                      :key="notificationTypeOption.value"
                      :class="{tabFiler_item: true, active: notificationType === notificationTypeOption.value}"
                      @click="setNotificationType(notificationTypeOption.value)"
                  >
                    {{ notificationTypeOption.title }}
                  </div>
                </div>
              </div>
              <div v-if="notifications.length" class="notificationsList">
                <template v-for="notification in notifications">
                  <DashboardNotification
                      :title="notification.data.title"
                      :category="notification.color"
                      :color="notification.color"
                      :url="'/myCalendar/myJobOffers/' + notification.offer_id">
                    <div v-if="notification.data.hasOwnProperty('bid')" class="notification_text">
                      <div>Offer price: <b>${{ notification.data.offer_price }}</b></div>
                      <div v-if="notification.data.bid?.price">Bid price: <b>${{ notification.data.bid.price }}</b></div>
                      <div v-if="notification.data.bid?.comment">Bid comment: <b>{{ notification.data.bid.comment }}</b></div>
                    </div>
                    <div class="notification_text text-limiter">
                      {{ notification.data.body }}
                    </div>
                    <template v-if="!notification.read_at && !offerIdsForIgnoreActionsList.includes(notification.offer_id)">
                      <div v-if="notification.subcategory === NOTIFICATION_SUBCATEGORY.SUBCATEGORY_JOB_DETAILS_BID" class="notification_actions">
                        <div class="new-btn new-btn-primary" @click="acceptBidConfirm(notification.data)">Accept</div>
                        <div class="new-btn new-btn-secondary" @click="cancelBidConfirm(notification.data)">Decline</div>
                      </div>
                      <div v-else-if="notification.subcategory === NOTIFICATION_SUBCATEGORY.SUBCATEGORY_JOB_DETAILS_INVITE" class="notification_actions">
                        <div class="new-btn new-btn-primary" data-target="modal-accept-offer" @click="openAcceptOfferModal(notification.data)">Accept</div>
                        <div class="new-btn new-btn-secondary" @click="declineOffer(notification.data)">Decline</div>
                        <div class="new-btn new-btn-link" @click="declineAndRecommend(notification.data)" data-target="modal-choose-friend">Decline and Recommend</div>
                        <div class="new-btn new-btn-link" data-target="modal-accept-offer" @click="openBidOfferModal(notification.data)">Bid other price
                        </div>
                      </div>
                    </template>
                  </DashboardNotification>
                </template>
              </div>
              <div v-else class="notificationsList">
                There are no new notifications
              </div>
              <pagination class="notificationPaginator" :current-page="currentPage"
                  :total-items="notificationsTotal"
                  :itemsPerPage="perPage"
                  @page-changed="pageChanged">
              </pagination>
            </div>
            <div>
              <div class="notificationsTop">
                <div class="columnTitle">Offers</div>
                <div class="columnPush"></div>
              </div>
              <div class="notificationsList">
                <router-link v-for="offer in filteredOffers" :to="'/myCalendar/myJobOffers/' + offer.id" :key="offer.id"
                    tag="div" :class="{notification: true, 'notification--gold': isOfferNeedAction(offer)}">
                  <div class="notification_title notification_title--link">
                    <svg v-if="isOfferHasUnreadNotification(offer)" width="10" height="10" viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                      <circle cx="5" cy="5" r="5" fill="#DFC43C"/>
                    </svg>
                    <span class="text-limiter">{{ offer.name }}</span>
                  </div>
                  <div class="notification_text text-limiter">
                    {{ offer.description }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="modal-choose-friend" class="modal">
      <div class="modal-head">Choose a freelancer</div>
      <div class="modal-content">
        <div class="thank-friend">
          <div class="search-friend">
            <div class="input-field search-field">
              <input type="search" placeholder="Find a freelancer" @keyup="filterFreelancersByName">
            </div>
          </div>
          <div class="friend-list">
            <div class="friend" v-for="freelancer in freelancerList" v-if="freelancer.id !== profileInfo.id">
              <div class="friend-col" @click="showFreelancerInNewTab(freelancer)">
                <div v-if="freelancer.image_url"
                    class="friend-icon"
                    id="freelancer-image"
                    :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancer.image_url + ')' }"></div>
                <div v-else class="friend-icon">
                  <img src="../../assets/images/profilePlaceholder.png" alt="">
                </div>
                <div class="friend-name">{{ freelancer.name }} {{ freelancer.lastname }}</div>
              </div>
              <app-button title="Send" :onClick="() => selectFreelancer(freelancer)"/>
            </div>
          </div>
        </div>
        <div>
          <app-button title="Cancel" cssClass="modal-close"/>
        </div>
      </div>
    </div>
    <div id="modal-accept-offer" class="modal">
      <div v-if="bidData.changePrice" class="modal-head">Propose another price</div>
      <div v-else class="modal-head">Accept offer</div>
      <div class="modal-content">
        <form v-if="bidData.changePrice" action="#">
          <div
              :data-error="errors.first('bidData.price')"
              :class="[{error: this.errors.has('bidData.price')}, 'input-field', 'required-field']"
          >
            <div class="label-field">Price, $</div>
            <div class="label-area">
              <input type="number"
                  step="1"
                  v-validate="`decimal:2|min_value:${bidData.minPrice}`"
                  data-vv-scope="bidData"
                  name="price"
                  v-model.lazy="bidData.price">
            </div>
          </div>
          <div class="input-field">
            <div class="label-field">Comment</div>
            <div class="label-area">
              <textarea class="materialize-textarea" aria-invalid="false" rows="5"
                  v-model.lazy="bidData.comment"></textarea>
            </div>
          </div>
        </form>
        <div v-else class="row offer-price">
          <div class="col">
            Offer price: ${{ bidData.offerPrice }}
          </div>
        </div>
        <div class="modal-content__button-wrapper">
          <app-button :title="bidData.changePrice ? 'Send Proposition': 'Accept Offer'" :onClick="sendBidOrAssign" />
          <app-button title="Cancel" cssClass="modal-close"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardNotification from '@/components/DashboardNotification.vue'
import {NOTIFICATION_CATEGORY, NOTIFICATION_SUBCATEGORY, OFFER_STATUSES} from '@/helpers/Enums'
import {swalOptionsForQuestion} from '@/misc/swallOptions'
import {mapActions, mapGetters} from 'vuex'
import Button from '../../components/Button.vue'
import Pagination from '../../components/Pagination'
import Select from '../../components/Select'
import ImageHelper from '../../helpers/ImageHelper'
import RolesHelper from '../../helpers/RolesHelper'
import * as types from '../../vuex/profile/mutationTypes'

const freshBidData = {
  offerId: null,
  offerPrice: '0',
  changePrice: false,
  price: '0',
  minPrice: '0',
  comment: ''
}

let searchTimer

export default {
  name: 'Dashboard',
  mixins: [ImageHelper, RolesHelper],
  components: {
    'app-button': Button,
    DashboardNotification,
    'app-select': Select,
    'pagination': Pagination
  },
  data: () => ({
    currentPage: 1,
    perPage: 15,
    bidData: {...freshBidData},
    calendar: null,
    tab: 'schedule',
    NOTIFICATION_CATEGORY: NOTIFICATION_CATEGORY,
    NOTIFICATION_SUBCATEGORY: NOTIFICATION_SUBCATEGORY,
    notificationType: 'ALL',
    notificationTypes: [
      {value: 'ALL', title: 'All'},
      {value: NOTIFICATION_CATEGORY.PAYMENT, title: 'Payments'},
      {value: NOTIFICATION_CATEGORY.JOB_DETAILS, title: 'Job Details'}
    ],
    filteredOffers: []
  }),
  computed: {
    ...mapGetters(
        ['calendarOffers', 'profileInfo', 'lastNotification', 'notifications', 'freelancerList', 'notificationsTotal', 'offerIdsForIgnoreActionsList', 'isFreelancer']),
  },
  watch: {
    profileInfo: function (profile) {
      this.fetchCalendarOffers(profile)
    },
    lastNotification: function (notification) {
      this.currentPage = 1
      this.fetchNotifications({
        callBack: () => this.removeFromIgnoreActionIds(notification.offer_id),
        category: this.notificationType
      })
    },
    calendarOffers: function () {
      this.updateCalendar(this.calendarOffers)
      const viewData = this.calendar.fullCalendar('getView')
      this.setFilteredOffers(viewData)
    },
    tab(currentTab) {
      if (currentTab === 'schedule') {
        this.$nextTick(() => this.initCalendar())
      }
    },
    notificationType() {
      this.currentPage = 1
      this.fetchNotifications({category: this.notificationType})
    }
  },
  methods: {
    ...mapActions(
        ['fetchCalendarOffers', 'fetchNotifications', 'cancelInvite', 'placeOfferBid', 'assignOffer', 'cancelOfferBid', 'acceptOfferBid', 'fetchFreelancerList',
          'recommendFreelancer', 'sendToFreelancer']),
    setTab(name) {
      this.tab = name
    },
    pageChanged(pageNum) {
      this.currentPage = pageNum
      this.fetchNotifications({page: pageNum, category: this.notificationType})
    },
    setNotificationType(type) {
      this.notificationType = type
    },
    openDate(date) {
      this.$router.push({
        name: 'myCalendar',
        params: {
          selectedDate: date.format('YYYY-MM-DD')
        }
      })
    },
    addToIgnoreActionIds(offerId) {
      this.$store.commit(types.NOTIFICATIONS_ADD_IGNORE_OFFERS, offerId)
    },
    removeFromIgnoreActionIds(offerId) {
      this.$store.commit(types.NOTIFICATIONS_REMOVE_IGNORE_OFFERS, offerId)
    },
    updateCalendar(events) {
      this.calendar.fullCalendar('removeEvents')
      this.calendar.fullCalendar('addEventSource', events)
    },
    setFilteredOffers(viewData) {
      this.filteredOffers = [...this.calendarOffers].filter(event => {
        return (event.date >= viewData.start.format('YYYY-MM-DD') && viewData.end.format('YYYY-MM-DD') > event.date)
      })
    },
    openAcceptOfferModal(data) {
      this.bidData = {
        ...freshBidData,
        offerId: data.offer_id,
        offerPrice: data.offer_price,
        changePrice: false
      }
    },
    openBidOfferModal(data) {
      this.bidData = {
        ...freshBidData,
        offerId: data.offer_id,
        offerPrice: data.offer_price,
        price: data.offer_price,
        changePrice: true
      }
    },
    declineOffer(data) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Your invitation will be declined',
        ...swalOptionsForQuestion
      }).then(() => {
        this.addToIgnoreActionIds(data.offer_id)
        this.cancelInvite(data.offer_id)
      })
    },
    sendBidOrAssign() {
      if (this.bidData.changePrice) {
        this.$validator.validateAll('bidData').then(result => {
          if (!result) return
          this.addToIgnoreActionIds(this.bidData.offerId)
          this.placeOfferBid({
            offer_id: this.bidData.offerId,
            freelancer_id: this.profileInfo.id,
            price: this.bidData.price,
            comment: this.bidData.comment
          })
          this.bidData = {...freshBidData}
          window.$('.modal').modal('close')
        })
      } else {
        this.addToIgnoreActionIds(this.bidData.offerId)
        this.assignOffer({offer_id: this.bidData.offerId, freelancer_id: this.profileInfo.id})
        this.bidData = {...freshBidData}
        window.$('.modal').modal('close')
      }
    },
    acceptBidConfirm(data) {
      const additionalText = data.bid.price
          ? `<br/><br/>Attention!<br/>You will approve proposed price <span class="red-text">$${data.bid.price}</span> from freelancer.` : ''
      this.$swal({
        title: 'Are you sure? You will assign freelancer to this offer.',
        html: 'This action cannot be undone' + additionalText,
        ...swalOptionsForQuestion
      }).then(() => {
        this.acceptBid(data)
      })
    },
    acceptBid(data) {
      this.addToIgnoreActionIds(data.offer_id)
      this.acceptOfferBid({offer_id: data.bid.offer_id, bid_id: data.bid.id})
    },
    cancelBidConfirm(data) {
      this.$swal({
        title: 'Are you sure?',
        html: 'This action cannot be undone',
        ...swalOptionsForQuestion
      }).then(() => {
        if (data.offer_public) {
          this.cancelBid(data)
        } else {
          this.cancelInvite(data.bid.offer_id)
        }
      })
    },
    cancelBid(data) {
      this.addToIgnoreActionIds(data.offer_id)
      this.cancelOfferBid({offer_id: data.bid.offer_id, freelancer_id: data.bid.freelancer_id})
    },
    declineAndRecommend(data) {
      this.bidData = {
        ...freshBidData,
        offerId: data.offer_id
      }
      this.fetchFreelancerList({role_id: this.profileInfo.role.id})
    },
    filterFreelancersByName(e) {
      clearTimeout(searchTimer)
      searchTimer = setTimeout(() => {
        this.fetchFreelancerList({search: e.target.value})
      }, 1000)
    },
    selectFreelancer(freelancer) {
      window.$('.modal').modal('close')
      this.addToIgnoreActionIds(this.bidData.offerId)
      this.cancelInvite(this.bidData.offerId)
      this.recommendFreelancer({offer: this.bidData.offerId, freelancer: freelancer.id, stayHere: true})
    },
    initCalendar() {
      this.calendar = window.$('#mini-calendar-dashboard').fullCalendar({
        header: {
          left: 'prev',
          center: 'title',
          right: 'next'
        },
        views: {
          month: { // name of view
            titleFormat: 'MMMM, YYYY'
          }
        },
        changeMonth: true,
        changeYear: true,
        height: 350,
        events: this.calendarOffers,
        dayClick: (date, jsEvent, view) => {
          this.openDate(date)
        },
        eventRender: (event, element) => {
          const warningStatuses = [OFFER_STATUSES.DECLINED, OFFER_STATUSES.CANCELLED]
          const eventClass = warningStatuses.includes(
              event.status.id) ? 'dayWithEvent eventWithWarning' : 'dayWithEvent'
          window.$(
              `#mini-calendar-dashboard .fc-body thead td[data-date='${event.start.format('YYYY-MM-DD')}']`).addClass(
              eventClass)
        },
        viewRender: (view, element) => {
          this.setFilteredOffers(view)
        }
      })
    },
    isOfferNeedAction(offer) {
      if (this.profileInfo.id) {
        if (this.isCompany(this.profileInfo)) {
          return [
                OFFER_STATUSES.DRAFT_OFFER,
                OFFER_STATUSES.BOOKING_ACCEPTED,
                OFFER_STATUSES.OFFER_IN_REVIEW,
                OFFER_STATUSES.DECLINED
              ].includes(offer.status.id) ||
              offer.biddedFreelancers.length ||
              offer.recommendedFreelancers.length
        } else {
          return [
            OFFER_STATUSES.OFFER_PUBLISHED,
            OFFER_STATUSES.OFFER_IS_PAYED,
            OFFER_STATUSES.OFFER_IN_PROGRESS,
            OFFER_STATUSES.PENDING_ALERT
          ].includes(offer.status.id)
        }
      }
      return false
    },
    isOfferHasUnreadNotification(offer) {
      return offer.unread;
    },
    redirectToOfferCreation() {
      if (this.isFreelancer) {
        this.$router.push('/jobBoard/');
      } else {
        this.$router.push('/myCalendar/offerCreation');
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.initCalendar())
    this.profileInfo.id && this.fetchCalendarOffers(this.profileInfo)
    this.fetchNotifications({category: this.notificationType})

    window.$('.modal').modal({
      ready() {
        window.$('.modal-overlay').detach().appendTo('.wrapper')
      }
    })
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/style-new";

.dashboardWrapper {
  padding-top: 50px;
  margin: 0 auto;

  &__inner-container {
    display: grid;
    grid-template-columns: auto;
    gap: 30px;

    @media (min-width: 1024px) {
      grid-template-columns: 30% auto;
    }
  }

  .mobileNav {
    display: none;
  }

  .columnTitle {
    @include frr;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.578824px;
    color: #272727;
    margin-bottom: 15px;
  }

  .columnPush {
    display: none;

    @media (min-width: 600px) {
      display: block;
      height: 30px;
    }
  }

  .notificationsTop {

  }

  .tabFiler {
    display: flex;
    height: 30px;
    line-height: 30px;
    white-space: nowrap;

    &_item {
      @include frr;
      cursor: pointer;
      text-align: center;
      padding: 0 20px;
      min-width: 90px;

      &.active {
        border-bottom: 1px solid #dfc43c;
      }
    }
  }

  .scheduleWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  .notificationWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    @media (min-width: 600px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .notificationsList {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .notification {
      box-sizing: border-box;
      padding: 21px;
      background-color: #ffffff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      cursor: pointer;

      &--gold {
        border-top: 4px solid $cgold;
      }

      &--green {
        border-left: 4px solid #1d991b;
        @media (min-width: 992px) {
          border-left: 8px solid #1d991b;
        }
      }

      &--yellow {
        border-left: 4px solid #ffbb00;
        @media (min-width: 992px) {
          border-left: 8px solid #ffbb00;
        }
      }

      &--red {
        border-left: 4px solid #fe0500;
        @media (min-width: 992px) {
          border-left: 8px solid #fe0500;
        }
      }

      &_title {
        @include frr;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        line-height: 21px;

        &--link {
          cursor: pointer;
          color: $t;
        }
      }

      &_text {
        margin-top: 10px;
        font-size: 14px;
        line-height: 16px;
      }

      &_actions {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;

        .btn {

        }
      }
    }
  }

  .notificationPaginator {
    margin-top: 20px;
  }

  #mini-calendar-dashboard {
    margin-top: 15px;
    background: #ffffff !important;
    border-radius: 4px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);

    .fc-header-toolbar {
      border-top: unset;

      .fc-center h2 {
        font-size: 18px !important;
      }
    }

    .fc-view {
      background: white !important;

      span {
        color: #797979 !important;
      }
    }

    .fc-day-header, .fc-other-month {
      background: white !important;
    }

    .fc-day-number {
      border-radius: 50%;

      &:hover {
        background: #fcefb0 !important;
      }
    }

    .fc-row td {
      text-align: center !important;

      span {
        @include frb;
        display: inline-block !important;
        padding-right: unset !important;
        width: 40px !important;
        float: unset !important;
      }
    }

    .dayWithEvent:not(.fc-today) {

      &.eventWithWarning {
        span {
          background: #ed4e4e !important;
        }
      }

      span {
        background: #d0ab24 !important;
      }

      &:hover {
        span {
          color: #ffffff !important;
        }
      }
    }
  }

  .what-next {
    background: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    height: 340px;
    margin-top: 0;

    @media (min-width: 768px) {
      margin-top: 39px;
    }

    @media (min-width: 1024px) {
      margin-top: 0;
    }

    &__inner-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__image-top {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
    }

    &__image-right {
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
    }

    &__content {
      position: absolute;
      bottom: 20px;
      left: 0;
      z-index: 2;
      width: 70%;
    }

    &__title {
      @include fm;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 10px;

      @media (min-width: 460px) {
        font-size: 18px;
      }
    }

    &__text {
      @include fm;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px;

      @media (min-width: 460px) {
        font-size: 16px;
      }
    }

    .plus {
      align-items: center;
      background: linear-gradient(90deg, #cda61f 0%, #f2e35a 100%);
      border-radius: 50%;
      color: #000000;
      display: inline-flex;
      font-size: 16px;
      height: 20px;
      line-height: 20px;
      justify-content: center;
      width: 20px;
    }
  }
}

#modal-accept-offer {
  textarea {
    min-height: 140px !important;
  }

  .offer-price {
    font-weight: bold;
    margin: 10px 0 30px;
  }
}

@media all and (max-width: 426px) {
  .dashboardWrapper {
    display: flex;
    flex-direction: column;
    gap: 0;

    .mobileNav {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .tabFiler {
        font-size: 14px;
        margin-top: 3px;

        &_item {
          padding: 0 7px;
          min-width: auto;
        }
      }

      input.select-dropdown {
        margin-bottom: 0;
        border-bottom: none;
        height: 2rem;
        line-height: 2rem;
      }
    }

    .dashboardTab {
      display: none;

      &--active {
        display: block;
        width: 100%;
      }
    }

    .notificationsTop {
      display: none;
    }

    .notification {
      &_actions {
      }
    }

  }
}


</style>
