<template>
  <router-link :to="url" tag="div" :class="notificationClasses">
    <div class="notification_title notification_title--link">
      <img v-if="color === 1" src="@/assets/images/notification/green.svg" alt="">
      <img v-if="color === 2" src="@/assets/images/notification/yellow.svg" alt="">
      <img v-if="color === 3" src="@/assets/images/notification/red.svg" alt="">
      <span class="text-limiter">{{ title }}</span>
    </div>
    <slot/>
  </router-link>
</template>

<script>
import {NOTIFICATION_CATEGORY} from "@/helpers/Enums";

export default {
  name: "DashboardNotification",
  computed: {
    NOTIFICATION_CATEGORY() {
      return NOTIFICATION_CATEGORY
    },
    notificationClasses() {
      return {
        notification: true,
        'notification--green': this.color === 1,
        'notification--yellow': this.color === 2,
        'notification--red': this.color === 3,
      };
    },
  },
  props: {
    url: String,
    color: Number,
    title: String,
    category: Number,
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/var_and_mixins";

.notification {
  box-sizing: border-box;
  padding: 21px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  cursor: pointer;

  &--gold {
    border-top: 4px solid $cgold;
  }

  &--green {
    border-left: 4px solid #1d991b;
    @media (min-width: 992px) {
      border-left: 8px solid #1d991b;
    }
  }

  &--yellow {
    border-left: 4px solid #ffbb00;
    @media (min-width: 992px) {
      border-left: 8px solid #ffbb00;
    }
  }

  &--red {
    border-left: 4px solid #fe0500;
    @media (min-width: 992px) {
      border-left: 8px solid #fe0500;
    }
  }

  &_title {
    @include frr;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    line-height: 21px;

    &--link {
      cursor: pointer;
      color: $t;
    }
  }

  &_text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 16px;
  }

  &_actions {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;

    .btn {

    }
  }
}
</style>