<template>
  <div class="notice-box">
    <a data-activates="dropdown2" class="dropdown-button icon-ico-16">
      <span class="notice-count" v-if="notificationsUnread.length > 0">{{notificationsUnread.length }}</span>
    </a>
    <div id="dropdown2" class="dropdown-content notice-drop">
      <div class="notice-drop__head">Notifications</div>
      <div class="notice-drop__body">
        <div v-for="notification in shownItems" class="notice-item"
            @click="handleClickView(getIdFromNotification(notification))">
          <div class="notice-item__box">
            <div class="notice-item__title">{{ notification.data.title }}</div>
            <div>
              {{ notification.data.body }}
            </div>
          </div>
          <div class="notice-item__date">{{calendarDate(notification.created_at)}}</div>
        </div>
      </div>
      <div class="notice-drop__footer" v-if="notificationsUnread.length > 3">
        <a @click="changeShowAll">
          <span v-if="showAll">Hide</span>
          <span v-else>Show all</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import DateHelper from '../helpers/DateHelper'
  export default {
    data () {
      return {
        showAll: false
      }
    },
    computed: {
      ...mapGetters(['notificationsUnread']),
      shownItems () {
        return this.showAll ? this.notificationsUnread : this.notificationsUnread.slice(0, 3)
      }
    },
    methods: {
      ...mapActions(['fetchUnreadNotifications', 'removeUnreadNotifications']),
      getIdFromNotification (notification) {
        return notification.hasOwnProperty('offer_id') ? notification['offer_id'] : ''
      },
      changeShowAll (e) {
        e.stopPropagation()
        this.showAll = !this.showAll
      },
      handleClickView (offerId) {
        this.removeUnreadNotifications(offerId)
        this.$router.push('/myCalendar/myJobOffers/' + offerId)
      }
    },
    mixins: [DateHelper],
    mounted () {
      window.$('.dropdown-button').dropdown({
        outDuration: 301,
        stopPropagation: true
      })
      this.fetchUnreadNotifications()
    }
  }
</script>
