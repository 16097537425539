<template>
  <div class="birthday-wrapper">
    <app-select name="month" v-model="selectedMonth" @input="updateDate">
      <option value disabled selected>Month</option>
      <option v-for="(month, index) in months" :key="index" :value="index+1">{{ month }}</option>
    </app-select>

    <app-select name="day" v-model="selectedDay" @input="updateDate">
      <option value disabled selected>Day</option>
      <option v-for="day in days" :key="day" :value="day">{{ day }}</option>
    </app-select>

    <app-select name="year" v-model="selectedYear" @input="updateDate">
      <option value disabled selected>Year</option>
      <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
    </app-select>
  </div>
</template>

<script>
import Select from '@/components/Select.vue';
import moment from "moment";

export default {
  data() {
    return {
      selectedYear: null,
      selectedMonth: null,
      selectedDay: null,
      years: [...Array(new Date().getFullYear() - 1960).keys()].map(i => i + 1945).reverse(),
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      days: [...Array(31).keys()].map(i => i + 1)
    };
  },
  props: {
    value: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    }
  },
  watch: {
    value(newVal) {
      if (newVal) {
        const date = moment(newVal);
        this.selectedYear = date.year();
        this.selectedMonth = date.month() + 1;
        this.selectedDay = date.date();
      } else {
        this.selectedDay = this.selectedMonth = this.selectedYear = null;
      }
    }
  },
  methods: {
    updateDate() {
      if (this.selectedYear && this.selectedMonth && this.selectedDay) {
        const year = this.selectedYear;
        const month = this.selectedMonth.toString().padStart(2, '0');
        const day = this.selectedDay.toString().padStart(2, '0');
        const newDate = `${year}-${month}-${day}`;

        this.$emit('input', newDate);
      } else {
        this.$emit('input', null);
      }
    }
  },
  components: {
    'app-select': Select,
  }
};
</script>

<style lang="scss">
.birthday-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .select-wrapper {
    input.select-dropdown {
      padding-right: 20px;
      background: #fff !important;
    }

    span.caret {
      right: 10px;
      z-index: 5 !important;
    }
  }
}
</style>
