import ConfigHelper from './ConfigHelper'

const icons = {
  pdf: 'pdf.png',
  doc: 'doc.png',
  xls: 'xls.png',
  jpg: 'jpg.png',
  jpeg: 'jpg.png',
  png: 'png.png',
  zip: 'zip.png',
  file: 'file.png'
}

export default {
  methods: {
    dataURItoBlob (dataURI) {
      let byteString
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1])
      } else {
        byteString = unescape(dataURI.split(',')[1])
      }
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ia], {type: mimeString})
    },
    getStorageUrl () {
      return ConfigHelper.get('storageUrl')
    },
    getFileExtension (fileUrl) {
      return fileUrl.split('.').pop()
    },
    getIcon (url) {
      let key = this.getFileExtension(url)
      let fileName = icons.hasOwnProperty(key) ? icons[key] : icons['file']
      return `/assets/fileTypes/${fileName}`
    }
  }
}
