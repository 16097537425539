import axios from 'axios'
import ConfigHelper from '../../helpers/ConfigHelper'
import * as types from './mutationTypes'

const BASE_URL = ConfigHelper.get('apiUrl')
const COMPANY_LIST_URL = BASE_URL + '/companies'
const COMPANY_DETAILS_URL = (id) => `${COMPANY_LIST_URL}/${id}`
const COMPANY_OFFERS_URL = (id) => `${COMPANY_LIST_URL}/${id}/offers`
const COMPANY_REWIEWS = (id) => BASE_URL + `/company/${id}/recalls`
const COMPANY_ADD_RATING_URL = BASE_URL + '/company/rating/add'
const COMPANY_ADD_COMPLAIN_URL = BASE_URL + '/companies/complain'

const state = {
  isProcessing: false,
  fetchError: null,
  companyList: [],
  companyTotal: 0,
  companyListPage: 1,
  companyDetails: {},
  companyOffers: [],
  companyOffersTotal: 0,
  companyReviews: []
}

const getters = {
  companyList (state) {
    return state.companyList
  },
  companyTotal (state) {
    return state.companyTotal
  },
  companyListPage (state) {
    return state.companyListPage
  },
  companyDetails (state) {
    return state.companyDetails
  },
  companyOffers (state) {
    return state.companyOffers
  },
  companyOffersTotal (state) {
    return state.companyOffersTotal
  },
  companyReviews (state) {
    return state.companyReviews
  }
}

const actions = {
  fetchCompanyList ({commit, state}, pageNum = 1) {
    commit(types.COMPANY_LIST_FETCH_PROCESSING)
    axios.get(`${COMPANY_LIST_URL}?page=${pageNum}`).then((res) => {
      commit(types.COMPANY_LIST_FETCH_SUCCESS, {
        companyList: res.data.data,
        companyListPage: pageNum,
        companyTotal: res.data.meta.total
      })
    }).catch(error => {
      commit(types.COMPANY_LIST_FETCH_ERROR, error)
    })
  },

  fetchCompanyDetails ({commit, state}, id) {
    commit(types.COMPANY_DETAILS_FETCH_PROCESSING)
    axios.get(COMPANY_DETAILS_URL(id)).then((res) => {
      commit(types.COMPANY_DETAILS_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.COMPANY_DETAILS_FETCH_ERROR, error)
    })
  },

  fetchCompanyOffers ({commit, state}, {id, page = 1}) {
    commit(types.COMPANY_OFFERS_FETCH_PROCESSING)
    axios.get(COMPANY_OFFERS_URL(id), {params: {page, only_public: true}}).then((res) => {
      commit(types.COMPANY_OFFERS_FETCH_SUCCESS, res.data)
    }).catch(error => {
      commit(types.COMPANY_OFFERS_FETCH_ERROR, error)
    })
  },

  addCompanyRating ({commit, state}, data) {
    commit(types.COMPANY_ADD_RATING_PROCESSING)
    axios.post(COMPANY_ADD_RATING_URL, data).then((res) => {
      commit(types.COMPANY_ADD_RATING_SUCCESS, res.data.data)
      if (res.data && res.data.message.length) {
        window.Materialize.toast(res.data.message, 2000)
      } else {
        window.Materialize.toast('Your feedback added', 2000)
      }
    }).catch(error => {
      window.Materialize.toast(error.data.message, 2000)
      commit(types.COMPANY_ADD_RATING_ERROR, error)
    })
  },

  addCompanyComplain ({commit, state}, data) {
    axios.post(COMPANY_ADD_COMPLAIN_URL, data).then((res) => {
      if (res.data && res.data.message.length) {
        window.Materialize.toast(res.data.message, 2000)
      } else {
        window.Materialize.toast('Your complain sended', 2000)
      }
    }).catch(error => {
      window.Materialize.toast(error, 2000)
    })
  },

  fetchCompanyReviews ({commit, state}, id) {
    commit(types.COMPANY_REWIEWS_FETCH_PROCESSING)
    axios.get(COMPANY_REWIEWS(id)).then((res) => {
      commit(types.COMPANY_REWIEWS_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.COMPANY_REWIEWS_FETCH_ERROR, error)
    })
  },

  companyDetailsCancel ({commit, state}) {
    commit(types.COMPANY_DETAILS_CANCEL)
  }
}

const mutations = {
  [types.COMPANY_DETAILS_CANCEL] (state) {
    state.companyDetails = {}
    state.companyOffers = {}
    state.companyOffersTotal = 0
    state.companyReviews = {}
    return state
  },
  [types.COMPANY_REWIEWS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.COMPANY_REWIEWS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.companyReviews = data
    return state
  },
  [types.COMPANY_REWIEWS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.COMPANY_LIST_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.COMPANY_LIST_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.companyList = data.companyList
    state.companyTotal = data.companyTotal
    state.companyListPage = data.companyListPage
    return state
  },
  [types.COMPANY_LIST_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.COMPANY_DETAILS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.COMPANY_DETAILS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.companyDetails = data
    return state
  },
  [types.COMPANY_DETAILS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.COMPANY_OFFERS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.COMPANY_OFFERS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.companyOffers = data.data
    state.companyOffersTotal = data.meta.total
    return state
  },
  [types.COMPANY_OFFERS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.COMPANY_ADD_RATING_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.COMPANY_ADD_RATING_SUCCESS] (state) {
    state.isProcessing = false
    return state
  },
  [types.COMPANY_ADD_RATING_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
